<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-calendar-alt"></i>
							Farm Logs
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link :to="'/sub_farms/'+sub_farm_id+'?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-secondary">
								<i class="fas fa-arrow-left"></i>
								Back
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-12" v-can="'logs.view'">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
                                <strong>Farm Logs</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-12">
                                        <table class="table table-responsive-sm table-bordered table-striped table-sm" v-can="'logs.create'">
                                            <thead>
                                                <tr>
                                                    <th width="20%" class="text-center">
                                                        Date
                                                    </th>
                                                    <th width="25%" class="text-center">
                                                        Activity
                                                    </th>
                                                    <th width="50%" class="text-center">
                                                       Description
                                                    </th>
                                                    <th width="5%" class="text-center">
                                                        <i class="fas fa-exchange-alt"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
												<tr>
													<td>
														<input class="form-control form-control-sm" :class="{'is-invalid': errors.date}" type="date" v-model="log.date" placeholder="date" ref="date">
                                                        <div v-if="errors.date" class="invalid-feedback">{{ errors.date[0] }}</div>
													</td>
													<td>
														<input class="form-control form-control-sm" :class="{'is-invalid': errors.activity}" type="text" v-model="log.activity" placeholder="Activity">
                                                        <div v-if="errors.activity" class="invalid-feedback">{{ errors.activity[0] }}</div>
													</td>
													<td>
														<input class="form-control form-control-sm" :class="{'is-invalid': errors.description}" type="text" v-model="log.description" placeholder="Description">
                                                        <div v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</div>
													</td>
													<td class="text-center">
														<button v-if="status" class="btn btn-outline-success" type="button" @click="create">
                                                            <i class="fas fa-plus"></i>
                                                        </button>
                                                        <button v-else class="btn btn-outline-success" type="button" @click="update">
                                                            <i class="fas fa-redo-alt"></i>
                                                        </button>
													</td>
												</tr>
												<tr v-for="log in logs" :key="log.log_id">
													<td>{{ $filters.formatDate(log.date) }}</td>
													<td>{{ log.activity}}</td>
													<td>{{ log.description}}</td>
													<td class="text-nowrap text-center">
                                                        <button v-can="'logs.update'" class="btn btn-outline-success" type="button" @click="edit(log)">
                                                            <i class="far fa-edit"></i>
                                                        </button>

                                                        <button v-can="'logs.delete'" class="btn btn-outline-danger" type="button" @click="destory(log)">
                                                            <i class="far fa-trash-alt"></i>
                                                        </button>
                                                    </td>
												</tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'Logs.Create',
		data(){
            return{
                status:true,
				log:{
					log_id:'',
					user_id:'',
					farm_id:'',
					sub_farm_id:'',
					date:'',
					activity:'',
					description:'',
				},
				logs:[],
				farm_id:'',
				sub_farm_id:'',
				errors:[],
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.farm_id = to.query.farm_id;
				vm.sub_farm_id = to.query.sub_farm_id;
				vm.log.user_id = vm.$store.getters.user.user_id
				vm.log.farm_id = vm.farm_id
				vm.log.sub_farm_id = vm.$route.params.sub_farm_id
				vm.getLogs()
			});
        },

		methods:{

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'logs','data':vm.log};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Farm Log is successfully created');
					vm.getLogs()
					vm.discard()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
			getLogs(){
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'logs?sub_farm_id='+vm.sub_farm_id,'data':vm.log};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.logs = response.data.data
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'logs/'+vm.log.log_id,'data':vm.log};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Farm log is successfully updated');
					vm.getLogs()
					vm.discard()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			destory(log){
				if(confirm("Are you sure you want to delete?")){
					let vm = this;
					let loader = vm.$loading.show();
					let uri = {'uri':'logs/'+log.log_id,'data':vm.log};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Farm Log is successfully deleted');
						vm.getLogs()
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

			edit(log) {
				let vm = this;
				vm.log = log;
				vm.status = false;
			},

			discard() {
				let vm = this;
				vm.log.log_id = ''
				vm.log.date = ''
				vm.log.activity = ''
				vm.log.description = ''
			}
		}
	}
</script>
