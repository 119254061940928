<template>
	<nav class="main-header navbar navbar-expand-md navbar-light navbar-dark">
		<div class="container">
			<router-link to="/" class="navbar-brand">
				<img src="../assets/foster_innovation_logo_main.png" alt="K2 Farms" class="brand-image">
				<span style="padding-left:20px; padding-right:20px" class="brand-text font-weight-light"> Farm Management System</span>
			</router-link>
			
			<ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
				<li class="nav-item custom-nav-item" v-can="'configurations.view'">
					<router-link to="/configuration" class="nav-link">
						<i class="fas fa-cogs"></i> 
					</router-link>
				</li>
				<li class="nav-item custom-nav-item">
					<router-link to="/profile" class="nav-link">
						<i class="fas fa-user"></i> 
					</router-link>
				</li>
				<li class="nav-item custom-nav-item">
					<a class="nav-link" href="#" @click.prevent="logout">
						<i class="fas fa-sign-out-alt"></i> 
					</a>
				</li>
			</ul>
		</div>
	</nav>
	<FarmModal
		:status="status"
		@onStatusChange="onStatusChange"
	/>

</template>
<script>
	export default {
		name: 'Header',
		data(){
			return{
				status:false,
			}
		},
		methods:{
            onStatusChange(status) {
                this.status = status;
            },
			logout() {
				this.$store.dispatch('logout');
				this.$router.push('/login');
				location.reload();
			},
		}
	}
</script>

<style scoped>
	.nav-item {
		background:#4d4d4e;
		border-radius: 20px;
		margin-left: 5px !important;
	}
	.custom-nav-item {
		width: 43px;
		height: 40px;
	}

	.nav-item i {
		margin-left: -1px !important;
	}
</style>