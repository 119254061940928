<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<i class="fa fa-home"></i>
					Error
				</li>
			</ol>
		</nav>
		<!-- Main content -->
		<div class="content">
			<div class="container-fluid">
                <center><img src="../assets/branding.png" width="400" alt="Logo"></center>
				<h1 class="text-center">You don't have permission to access this page</h1>
			</div>
		</div>
	</div>
</template>

