<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<i class="fas fa-tachometer-alt"></i>
					Dashboard
				</li>
				<li class="breadcrumb-item">
					<i class="fas fa-tractor"></i>
					Farms
				</li>
                <li class="breadcrumb-right">
                    <router-link v-can="'farms.create'" to="/farms/create" class="btn btn-primary">
                        <i class="fa fa-plus"></i>
                        New Farm
                    </router-link>
                </li>
			</ol>
		</nav>
		<div class="content">
			<div class="container">
				<div class="card">
					<div class="card-header">
						<div class="card-title">
							<i class="fas fa-tractor"></i>
							Farms
						</div>
						<div class="card-tools">
							<input class="form-control search" type="text" placeholder="Search" v-model="meta.search" @keyup="index" ref="search">
						</div>
					</div>
					<div class="card-body">
						<table class="table table-responsive-sm  table-hover table-sm">
							<thead>
								<tr>
									<th class="text-center">#</th>
									<th>Farm Name</th>
									<th>Farm Type</th>
									<th>Contact Person</th>
									<th>Mobile No</th>
									<th>Total Area</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(farm,key) in farms" :key="key" class="cursor" @click="show(farm)">
									<td class="text-center">{{ meta.from+key }}</td>
									<td>{{ farm.farm_name }}</td>
									<td>{{ farm.farm_type }}</td>
									<td>{{ farm.contact_person }}</td>
									<td>{{ farm.mobile_no }}</td>
									<td>{{ farm.area }} Acre</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card-footer clearfix">
						<select class="form-control form-control-sm per_page float-left" type="text" placeholder="Search" v-model="meta.per_page" @change="onPerPageChange">
							<option>10</option>
							<option>15</option>
							<option>20</option>
							<option>25</option>
							<option>30</option>
						</select>
						<Pagination
							:maxPage="meta.maxPage"
							:totalPages="meta.lastPage"
							:currentPage="meta.currentPage"
							@pagechanged="onPageChange"
						/>
					</div>
				</div>	
			</div>
		</div>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination.vue';
	export default {
		name: 'farms.Index',
		components: { Pagination },
		data(){
            return{
				meta: {
                    search:'',
					order_by:'asc',
					keyword:'farm_name',
					per_page:10,
                    totalRows:0,
                    currentPage:1,
                    lastPage:1,
                    from: 1,
                    maxPage:1,
                },
				user_id:this.$store.state.user.user_id,
				role:this.$store.state.user.role,
				farms:[],
                errors:[]
            }
        },
		mounted() {
            let vm = this;
			vm.$refs.search.focus();
            vm.index();
        },
		methods:{

			index() {
				let vm = this;
				let uri = 'farms?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&keyword='+vm.meta.keyword+'&per_page='+vm.meta.per_page+'&user_id='+vm.user_id+'&role='+vm.role;
				vm.$store.dispatch('get',{'uri':uri})
				.then(function (response) {
					vm.farms = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			show(farm){
                this.$router.push('/farms/'+farm.farm_id)
            },

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

			onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            }
		}
	}
</script>
