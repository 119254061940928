<template>
    <div v-if="status">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-md" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    <i class="far fa-user"></i>
                                    Users
                                </h5>
                                <button type="button" class="close" @click="closeModal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label class="form-label">User *</label>
                                            <select class="form-control form-control-sm" :class="{'is-invalid': errors.user_id}" v-model="user_id" ref="user_id" @change="selectUser">
                                                <option value="">Select User</option>
                                                <option v-for="user,key in users" :key="key" :value="user.user_id">{{ user.name }} - {{ user.role.role }}</option>
                                            </select>
                                            <span v-if="errors.user_id" class="invalid-feedback">{{ errors.user_id[0] }}</span>
                                        </div>  
                                    </div>
                                </div>
                                <div class="row" v-if="investor=='Yes'">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Amount Invested *</label>
                                            <input type="text" class="form-control" :class="{'is-invalid': errors.investment}" placeholder="Amount Invested" v-model="investment">
                                            <span v-if="errors.investment" class="invalid-feedback">{{ errors.investment[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Lock in Period *</label>
                                            <input type="text" class="form-control" :class="{'is-invalid': errors.locking}" placeholder="Lock in Period" v-model="locking">
                                            <span v-if="errors.locking" class="invalid-feedback">{{ errors.locking[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Date of Investment *</label>
                                            <input type="date" class="form-control" :class="{'is-invalid': errors.investment_date}" v-model="investment_date">
                                            <span v-if="errors.investment_date" class="invalid-feedback">{{ errors.investment_date[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Date of Maturity *</label>
                                            <input type="date" class="form-control" :class="{'is-invalid': errors.maturity_date}" v-model="maturity_date">
                                            <span v-if="errors.maturity_date" class="invalid-feedback">{{ errors.maturity_date[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Expected Return *</label>
                                            <input type="text" class="form-control" :class="{'is-invalid': errors.return_expected}" placeholder="Expected Return" v-model="return_expected">
                                            <span v-if="errors.return_expected" class="invalid-feedback">{{ errors.return_expected[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Status *</label>
                                            <select class="form-control" :class="{'is-invalid': errors.investment_status}" placeholder="Status" v-model="investment_status">
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                            <span v-if="errors.investment_status" class="invalid-feedback">{{ errors.investment_status[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-sm btn-outline-danger" @click="closeModal">
                                    <i class="fas fa-ban"></i> Discard
                                </button>
                                <button type="button" class="btn btn-sm btn-outline-success" @click="saveUser">
                                   <i class="fas fa-save"></i> Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name:'Modal',
        props: {
            farm_id: {
                type: Number,
                required: true
            },
            sub_farm_id: {
                type: Number,
                required: true
            },
            investor_details: {
                type: Text,
                required: true
            },
            status: {
                type: Boolean,
                required: true
            },
        },
        data(){
            return{
                user_id:'',
                investment:'',
                locking:'',
                investment_date:'',
                maturity_date:'',
                return_expected:'',
                investment_status:'Active',
                investor:this.investor_details,
                users:[],
                errors:[]
            }
        },
        created(){
            this.getUsers()
        },
        methods: {
            closeModal() {
                this.user_id = "";
                this.investment = "";
                this.locking = "";
                this.investment_date = "";
                this.maturity_date = "";
                this.return_expected = "";
                this.investment_status = "";
                this.investor = "No";
                this.$emit('onStatusChange', false);    
            },

            getUsers() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'users?farm_id='+vm.farm_id+'&investor_details='+vm.investor_details};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.users = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

            selectUser() {
                let vm = this;
                if(vm.investor_details=='Yes') {
                    let users = vm.users.filter(function (el) {
                        return el.user_id == vm.user_id
                    });
                    if(users.length!=0) {
                        if(users[0].role.is_investor==1) {
                            vm.investor = "Yes";
                        }
                        else {
                            vm.investor = "No";
                        }
                    }
                    else {
                        vm.investor = "No";
                    }
                }
            },

            saveUser() { 
                let vm = this;
				let loader = vm.$loading.show();
                let data = {
                    'farm_id':vm.farm_id,
                    'sub_farm_id':vm.sub_farm_id,
                    'user_id':vm.user_id,
                    'investor':vm.investor,
                    'investment':vm.investment,
                    'locking':vm.locking,
                    'investment_date':vm.investment_date,
                    'maturity_date':vm.maturity_date,
                    'return_expected':vm.return_expected,
                    'investment_status':vm.investment_status,
                }
				let uri = {'uri':'farm_users','data':data};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch('success','Users is successfully assigned');
                    vm.user_id = "";
                    vm.investment = "";
                    vm.locking = "";
                    vm.investment_date = "";
                    vm.maturity_date = "";
                    vm.return_expected = "";
                    vm.investment_status = "";
                    vm.investor = "No";
                    vm.$emit('onSaveUser',response.data.data)
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
            }
        }
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
</style>