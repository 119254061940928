<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<i class="fa fa-home"></i>
					Dashboard
				</li>
				<li class="breadcrumb-item active">Schedule</li>
                <li class="breadcrumb-right">
                    <router-link to="/schedules" class="btn btn-sm btn-success">
                        <i class="fa fa-list"></i>
                        Schedule List
                    </router-link>
                </li>
			</ol>
		</nav>
		<div class="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
                                <strong v-if="status">New Schedule</strong>
                                <strong v-else>Update Schedule</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Location *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.location_id}" v-model="schedule.location_id">
												<option value="">Select location</option>
												<option v-for="location,key in locations" :key="key" :value="location.location_id">{{ location.location_name }}</option>
											</select>
											<span v-if="errors.location_id" class="invalid-feedback">{{ errors.location_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Crop *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.crop_id}" v-model="schedule.crop_id">
												<option value="">Select Crop</option>
												<option v-for="crop,key in crops" :key="key" :value="crop.crop_id">{{ crop.crop_name }}</option>
											</select>
											<span v-if="errors.crop_id" class="invalid-feedback">{{ errors.crop_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label>Cultivation Type *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.cultivation_type}" v-model="schedule.cultivation_type">
												<option value="">Select Cultivation Type</option>
												<option>Open</option>
												<option>Soil</option>
												<option>Hydroponics</option>
												<option>Soil</option>
											</select>
											<span v-if="errors.cultivation_type" class="invalid-feedback">{{ errors.cultivation_type[0] }}</span>
										</div>
									</div>
									<div class="col-sm-12">
                                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th width="12%" class="text-center">
                                                        Day
                                                    </th>
                                                    <th width="15%" class="text-center">
                                                        Status
                                                    </th>
                                                    <th width="18%" class="text-center">
                                                        Action
                                                    </th>
                                                    <th width="30%" class="text-center">
                                                        Compound
                                                    </th>
                                                    <th width="20%" class="text-center">
                                                        Dosage
                                                    </th>
                                                    <th width="5%" class="text-center">
                                                        <i class="fas fa-exchange-alt"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
												<tr>
													<td>
														<input class="form-control form-control-sm" :class="{'is-invalid': errors.day}" type="number" v-model="particular.day" placeholder="Day" ref="day">
                                                        <div v-if="errors.day" class="invalid-feedback">{{ errors.day[0] }}</div>
													</td>
													<td>
														<input class="form-control form-control-sm" :class="{'is-invalid': errors.status}" type="text" v-model="particular.status" placeholder="Status">
                                                        <div v-if="errors.status" class="invalid-feedback">{{ errors.status[0] }}</div>
													</td>
													<td>
														<select class="form-control form-control-sm" :class="{'is-invalid': errors.action_id}" v-model="particular.action_id">
															<option value="">Select Action</option>
															<option v-for="action,key in actions" :key="key" :value="action.action_id">{{ action.action_name }}</option>
														</select>
                                                        <div v-if="errors.action_id" class="invalid-feedback">{{ errors.action_id[0] }}</div>
													</td>
													<td>
														<input class="form-control form-control-sm" :class="{'is-invalid': errors.compound}" type="text" v-model="particular.compound" placeholder="Compound">
                                                        <div v-if="errors.compound" class="invalid-feedback">{{ errors.compound[0] }}</div>
													</td>
													<td>
														<input class="form-control form-control-sm" :class="{'is-invalid': errors.dosage}" type="text" v-model="particular.dosage" placeholder="Dosage">
                                                        <div v-if="errors.dosage" class="invalid-feedback">{{ errors.dosage[0] }}</div>
													</td>
													<td>
														<button v-if="particular.state" class="btn btn-sm btn-outline-success" type="button" @click="addRow">
                                                            <i class="fas fa-plus"></i>
                                                        </button>
                                                        <button v-else class="btn btn-sm btn-outline-success" type="button" @click="updateRow">
                                                            <i class="fas fa-redo-alt"></i>
                                                        </button>
													</td>
												</tr>
												<tr v-for="(particular, key) in schedule.particulars" :key="key">
                                                    <td>
                                                        {{ particular.day }}
                                                    </td>
                                                    <td>
                                                        {{ particular.status }}
                                                    </td>
													<td v-if="particular.action">
                                                        {{ particular.action.action_name }}
                                                    </td>
													<td v-else>
														{{ particular.action_id }}
													</td>
													<td>
                                                        {{ particular.compound }}
                                                    </td>
													<td>
                                                        {{ particular.dosage }}
                                                    </td>
                                                    <td class="text-nowrap">
                                                        <button class="btn btn-outline-success btn-sm" type="button" @click="editRow(particular,key)">
                                                            <i class="fas fa-pencil-alt"></i>
                                                        </button>

                                                        <button class="btn btn-outline-danger btn-sm" type="button" @click="deleteRow(particular,key)">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/schedules" class="btn btn-sm btn-outline-danger" type="button">
                                        <i class="fas fa-ban"></i> Discard
                                    </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'Schedules.Create',
		data(){
            return{
                status:true,
				schedule:{
					schedule_id:'',
					user_id:'',
					location_id:'',
					crop_id:'',
					cultivation_type:'',
					particulars:[],
					deleted_particulars:[],
				},
				particular:{
					particular_id:'',
					schedule_id:'',
					day:'',
					status:'',
					action_id:'',
					action:{},
					compound:'',
					dosage:'',
					state:true,
					key:'',
				},
				locations:[],
				crops:[],
				actions:[],
				errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				if(to.name=='Schedules.Create') {
					vm.schedule.user_id = vm.$store.getters.user.user_id;
					vm.getLocations();
				}
				else {
					let loader = vm.$loading.show();
					let uri = {'uri':'schedules/'+to.params.schedule_id};
					vm.$store.dispatch('get',uri)
					.then(function (response) {
						loader.hide();
						vm.getLocations();
						vm.schedule = response.data.data;
						vm.status = false;
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			});
        },

		watch:{
			'particular.action_id':function(){
				let vm = this;
				let action = vm.actions.filter(function (el) {
					return el.action_id == vm.particular.action_id 
				});
				vm.particular.action = action[0];
			}	
		},
		methods:{

			getLocations() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'locations','data':vm.schedule};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.locations = response.data.data;
					vm.getCrops();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getCrops() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'crops','data':vm.schedule};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.crops = response.data.data;
					vm.getActions();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getActions() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'actions','data':vm.schedule};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.actions = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'schedules','data':vm.schedule};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Schedule is successfully created');
					vm.$router.push('/schedules');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'schedules/'+vm.schedule.schedule_id,'data':vm.schedule};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Schedule is successfully updated');
					vm.$router.push('/schedules');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			checkRow() {
				let vm = this;
				if(vm.particular.state){
					vm.addRow();
				}
				else{
					vm.updateRow();
				}
			},

			addRow() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'schedule/validation','data':vm.particular};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					loader.hide();
					vm.schedule.particulars.push(response.data);
					vm.discard()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			editRow(particular, key) {
				let vm = this;
				vm.particular = particular;
				vm.particular.state = false;
				vm.particular.key = key;
			},

			updateRow() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'schedule/validation','data':vm.particular};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					loader.hide();
					vm.schedule.particulars.splice(response.data.key,1,response.data),
					vm.discard()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			deleteRow(particular,key) {
				let vm = this;
				vm.schedule.deleted_particulars.push(particular),
				vm.schedule.particulars.splice(key, 1);
				vm.discard();
			},

			discard() {
				let vm = this;
				vm.particular.particular_id="";
				vm.particular.schedule_id="";
				vm.particular.day="";
				vm.particular.status="";
				vm.particular.action_id="";
				vm.particular.action={};
				vm.particular.compound="";
				vm.particular.dosage="";
				vm.particular.state=true;
				vm.particular.key="";
				vm.errors = [];
				vm.$refs.day.focus();
			}
		}
	}
</script>
