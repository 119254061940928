<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-seedling"></i>
							Farms
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link to="/farms/create" class="btn btn-primary">
								<i class="fas fa-plus"></i>
								New Farm
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- Main content -->
		<div class="content">
			<div class="container">
				<div class="row"> 
					<div class="col-sm-4" v-for="farm,key in farms" :key="key">
						<div class="card card-widget widget-user">
                            <div class="widget-user-header bg-secondary">
                                <h3 class="widget-user-username">
                                    {{ farm.farm_name }}
                                </h3>
                                <h5 class="widget-user-desc" v-if="farm.area">
                                    {{ farm.area }} Acre
                                </h5>
								<h5 class="widget-user-desc" v-else>
                                    {{ farm.farm_type }}
                                </h5>
                            </div>
                            <div class="widget-user-image">
                                <img class="img-circle elevation-2" src="../assets/farm.jpg" alt="Image">
                            </div>
							<div class="card-body post" style="padding-top: 20px;">
								<div class="user-block">
                                    <div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-bars"></i> 
									</div>
                                    <span class="username">
                                        {{ farm.farm_type }}
                                    </span>
                                    <span class="description">
                                        Farm Type
                                    </span>
                                </div>
								<div class="user-block">
                                    <div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-map-marker-alt"></i>
									</div>
                                    <span class="username text">
                                        {{ farm.latitude }}
                                    </span>
                                    <span class="description">
                                        Latitude
                                    </span>
                                </div>
								<div class="user-block">
                                    <div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-map-marker-alt"></i>
									</div>
                                    <span class="username text">
                                        {{ farm.longitude }}
                                    </span>
                                    <span class="description">
                                        Longitude
                                    </span>
                                </div>
								<div class="user-block">
                                    <div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-map-marker-alt"></i>
									</div>
                                    <span class="username text">
                                        {{ farm.location }}
                                    </span>
                                    <span class="description">
                                        Address
                                    </span>
                                </div>
								<div class="text-center" v-can="'farms.view'">
									<button @click="show(farm)" class="btn btn-primary">
										Continue 
										<i class="fas fa-chevron-right"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		data(){
			return{
				filter:{
					farm_id:'',
					sub_farm_id:'',
					user_id:this.$store.state.user.user_id,
					role:this.$store.state.user.role,
					node_name:'',
					field_name:'',
					from_date:new Date().toISOString().slice(0,10),
					to_date:new Date().toISOString().slice(0,10),
				},
				farms:[],
				errors:[]
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				if(from.name=='Login') {
					location.reload();
				}
				vm.getFarms();
			});
		},
		methods:{
			getFarms() {
				let vm = this;
                let uri = {'uri':'farms?user_id='+vm.filter.user_id+'&role='+vm.filter.role};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.farms = response.data.data;
					if(vm.farms.length==1) {
						vm.show(vm.farms[0])
					}
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			show(farm){
                this.$router.push('/farms/'+farm.farm_id)
            },
		}
	}
</script>

<style scoped>
	.text {
		display: block;
		width: 300px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
</style>