<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="far fa-user"></i>
							Users
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link to="/users" class="btn btn-primary">
								<i class="fa fa-list"></i>
								Users
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-sm-4">
						<div class="card">
                            <div class="card-header">
                                <div class="card-title">
									<i class="far fa-user-circle"></i>
									Avatar
								</div>
                            </div>
                            <div class="card-body">
								<div class="text-center" v-if="user.avatar">
									<img width="200" :src="user.avatar">
								</div>
								<div class="form-group">
									<input type="file" class="form-control" id="customFile" :class="{'is-invalid': errors.avatar}" @change="onImageChange($event)" accept="image/*">
									<label class="form-label" for="customFile">Choose file</label>
								</div>
								<span v-if="errors.avatar" class="invalid-feedback">{{ errors.avatar[0] }}</span>
							</div>
						</div>
					</div>
					<div class="col-sm-8">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
								<div class="card-title">
									<i class="far fa-user"></i>
									<span v-if="status"> New User</span>
									<span v-else> Update User</span>
								</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Name *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.name}" placeholder="Name" v-model="user.name" ref="name">
											<span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Email Address *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="user.email">
											<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Mobile No *</label>
											<input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile No" v-model.number="user.mobile_no">
											<span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Role *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.role_id}" v-model="user.role_id">
												<option value="">Select Role</option>
												<option v-for="role,key in roles" :key="key" :value="role.role_id">{{ role.role }}</option>
											</select>
											<span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Aadhar No</label>
											<input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.aadhar_no}" placeholder="Aadhar No" v-model.number="user.aadhar_no">
											<span v-if="errors.aadhar_no" class="invalid-feedback">{{ errors.aadhar_no[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Qualification</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.qualification}" placeholder="Qualification" v-model="user.qualification">
											<span v-if="errors.qualification" class="invalid-feedback">{{ errors.qualification[0] }}</span>
										</div>
									</div>
									<div class="col-sm-12">
										<div class="form-group">
											<label class="form-label">Address *</label>
											<textarea class="form-control form-control-sm" :class="{'is-invalid': errors.address}" placeholder="Address" v-model="user.address"></textarea>
											<span v-if="errors.address" class="invalid-feedback">{{ errors.address[0] }}</span>
										</div>
									</div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/users" class="btn btn-outline-danger" type="button">
                                        <i class="fas fa-ban"></i> Discard
                                    </router-link>
                                    <button class="btn btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'User.Create',
		data(){
            return{
                status:true,
                user:{
					user_id:'',
					name:'',
					email:'',
					mobile_no:'',
					role_id:'',
					aadhar_no:'',
					qualification:'',
					address:'',
					avatar:'',
				},
				users:[],
				roles:[],
				errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				if(to.name=='Users.Create') {
					vm.getRoles();
					vm.$refs.name.focus();
				}
				else {
					let uri = {'uri':'users/'+to.params.user_id};
					vm.$store.dispatch('get',uri)
					.then(function (response) {
						vm.user = response.data.data;
						vm.user.avatar = vm.$store.getters.assetUrl+'/users/'+vm.user.avatar
						vm.status = false;
						vm.getRoles();
						vm.$refs.name.focus()
					})
					.catch(function (error) {
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			});
        },
		methods:{

			onImageChange(e) {
                e.preventDefault();
                let vm = this;
                let image = e.target.files[0]; 
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (e) => {
                    vm.user.avatar = e.target.result;
                };
            },

			getRoles() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'roles','data':vm.user};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.roles = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'users','data':vm.user};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','User is successfully created');
					vm.$router.push('/users');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(user){
                let vm = this;
                vm.user = user,
                vm.status = false,
                vm.$refs.user.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'users/'+vm.user.user_id,'data':vm.user};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','User is successfully updated');
					vm.$router.push('/users');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            }
		}
	}
</script>
