<template>
<div class="card-header nav1">
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <router-link :to="'/equipments?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="nav-link">
                <i class="fas fa-tools"></i> Equipment Expenses
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :to="'/labours?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="nav-link">
            <i class="far fa-user"></i>  Labour Expenses
            </router-link>
        </li>

        <li class="nav-item">
            <router-link :to="'/seeds?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="nav-link">
            <i class="fas fa-seedling"></i> Agriculture Input Expenses
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :to="'/maintainances?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="nav-link">
            <i class="fas fa-toolbox"></i> Maintainance
            </router-link>
        </li>
    </ul>
</div>
</template>

<script>
export default {
    name: 'ExpenseNav',
    props: {
        farm_id: {
            type: Number,
            required: true
        },
        sub_farm_id: {
            type: Number,
            required: true
        },
    },
}
</script>

<style scoped>
.nav1{
    margin-bottom: 10px;
    padding:0px;
    background-color: white;
}
</style>
