<template>
<div class="content-wrapper">
    <section class="content-header">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <h3>
                        <i class="fas fa-credit-card"></i>
                        Expenses
                    </h3>
                </div>
                <div class="col-sm-6">
                    <div class="float-sm-right">
                        <router-link :to="'/farms/'+farm_id" class="btn btn-secondary">
                            <i class="fas fa-arrow-left"></i>
                            Back
                        </router-link>
                        <router-link v-can="'expenses.create'" :to="'/maintainances/create?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-primary">
                            <i class="fa fa-plus"></i>
                            New Record
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="content">
        <div class="container">
            <ExpenseNav :farm_id="farm_id" :sub_farm_id="sub_farm_id" />
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">
                                <i class="fas fa-book"></i>
                                Maintainance Expenses
                            </div>
                            <div class="card-tools">
                                <input class="form-control form-control-sm search" type="text" placeholder="Search" v-model="meta.search" @keyup="index" ref="search">
                            </div>
                        </div>
                        <div class="card-body">
                            <table class="table table-responsive-sm  table-hover table-sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Farm</th>
                                        <!-- <th>Crop</th> -->
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Vendor</th>
                                        <th>Activity</th>
                                        <th class="text-right">Total</th>
                                        <th class="td-actions text-right">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(maintainance,key) in maintainances" :key="key">
                                        <td class="text-center">{{ meta.from+key }}</td>
                                        <td>{{ maintainance.farm.farm_name }}</td>
                                        <!-- <td>{{ maintainance.sub_farm.crop.crop_name }}</td> -->
                                        <td>{{ maintainance.date }}</td>
                                        <td>{{ maintainance.time }}</td>
                                        <td>{{ maintainance.vendor_name }}</td>
                                        <td>{{ maintainance.activity }}</td>
                                        <td class="text-right">{{ maintainance.total_cost }}</td>
                                        <td class="text-right">
                                            <button v-can="'expenses.update'" class="btn btn-xs btn-outline-success" @click="edit(maintainance)">
                                                <i class="fas fa-pencil-alt"></i>
                                            </button>
                                            <button v-can="'expenses.delete'" class="btn btn-xs btn-outline-danger" @click="destroy(maintainance)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer clearfix">
                            <select class="form-control form-control-sm per_page float-left" type="text" placeholder="Search" v-model="meta.per_page" @change="onPerPageChange">
                                <option>10</option>
                                <option>15</option>
                                <option>20</option>
                                <option>25</option>
                                <option>30</option>
                            </select>
                            <Pagination
                                :maxPage="meta.maxPage"
                                :totalPages="meta.lastPage"
                                :currentPage="meta.currentPage"
                                @pagechanged="onPageChange"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


</template>

<script>
import Pagination from '@/components/Pagination.vue';
import ExpenseNav from '@/components/ExpenseNav.vue';
export default {
    name: 'maintainances.Index',
    components: {
        Pagination,
        ExpenseNav
    },
    data() {
        return {
            meta: {
                search: '',
                order_by: 'desc',
                keyword:'date',
                per_page: 10,
                totalRows: 0,
                currentPage: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
            },
            farm_id:'',
            sub_farm_id:'',
            user_id:this.$store.state.user.user_id,
            maintainances: [],
            errors: []
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.farm_id = to.query.farm_id;
            vm.sub_farm_id = to.query.sub_farm_id;
            vm.$refs.search.focus();
            vm.index();
        });
    },
    methods: {

        index() {
            let vm = this;
            let uri = 'maintainances?page=' + vm.meta.currentPage + '&search=' + vm.meta.search + '&order_by=' + vm.meta.order_by + '&keyword=' + vm.meta.keyword + '&per_page=' + vm.meta.per_page+'&farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id;
            vm.$store.dispatch('get', {
                    'uri': uri
                })
                .then(function (response) {
                    vm.maintainances = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                });
        },

        edit(maintainance) {
            this.$router.push('/maintainances/'+maintainance.maintainance_id+'/edit?farm_id='+this.farm_id+'&sub_farm_id='+this.sub_farm_id)
        },

        destroy(maintainance) {
            let vm = this;
            if (confirm('Do you want to delete this entry ?')) {
                let loader = vm.$loading.show();
                let uri = {
                    'uri': 'maintainances/' + maintainance.maintainance_id
                };
                vm.$store.dispatch('delete', uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch('success', 'Expense is successfully deleted');
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch('error', error.response.data.message);
                    });
            }
        },

        onPageChange(page) {
            this.meta.currentPage = page;
            this.index();
        }
    }
}
</script>

