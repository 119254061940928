import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store/index.js';

import Login from '@/views/auth/Login.vue'
import Register from '@/views/auth/Register.vue'

import Dashboard from '@/views/Dashboard.vue'
import oldDashboard from '@/views/old.dashboard.vue'
import oldDashboard1 from '@/views/old-dashboard.vue'
import Profile from '@/views/Profile.vue'
import Error from '@/views/Error.vue'

import Configuration from '@/views/Configuration.vue'
import Roles from '@/views/configuration/Roles.vue'
import Permissions from '@/views/configuration/Permissions.vue'
import Crops from '@/views/configuration/Crops.vue'
import Stages from '@/views/configuration/Stages.vue'
import Actions from '@/views/configuration/Actions.vue'
import Nodes from '@/views/configuration/Nodes.vue'

import Users from '@/views/user/Index.vue'
import CreateUser from '@/views/user/Create.vue'

import Farms from '@/views/farm/Index.vue'
import CreateFarm from '@/views/farm/Create.vue'
import ShowFarm from '@/views/farm/Show.vue'
import FarmImages from '@/views/farm/Images.vue'

import SubFarms from '@/views/sub_farm/Index.vue'
import CreateSubFarm from '@/views/sub_farm/Create.vue'
import ShowSubFarm from '@/views/sub_farm/Show.vue'
import SubFarmsSchedule from '@/views/sub_farm/Schedule.vue'
import SubFarmImages from '@/views/sub_farm/Images.vue'
import SubFarmsLog from '@/views/sub_farm/Log.vue'

import Pests from '@/views/pest/Index.vue'
import CreatePest from '@/views/pest/Create.vue'
import ShowPest from '@/views/pest/Show.vue'

import Diseases from '@/views/disease/Index.vue'
import CreateDisease from '@/views/disease/Create.vue'
import ShowDisease from '@/views/disease/Show.vue'

import Sprays from '@/views/spray/Index.vue'
import CreateSpray from '@/views/spray/Create.vue'

import Equipments from '@/views/equipment/Index.vue'
import CreateEquipment from '@/views/equipment/Create.vue'

import Labours from '@/views/labour/Index.vue'
import CreateLabour from '@/views/labour/Create.vue'

import Seeds from '@/views/seed/Index.vue'
import CreateSeed from '@/views/seed/Create.vue'

import Maintainances from '@/views/maintainance/Index.vue'
import CreateMaintainance from '@/views/maintainance/Create.vue'

import Schedules from '@/views/schedule/Index.vue'
import CreateSchedule from '@/views/schedule/Create.vue'
import ShowSchedule from '@/views/schedule/Show.vue'

import Harvests from '@/views/harvest/Index.vue'
import CreateHarvest from '@/views/harvest/Create.vue'
import ShowHarvest from '@/views/harvest/Show.vue'

const routes = [

	//Auth
	{ 
		path: '/login', name: 'Login', 
		component: Login,  meta: { unAuth: true, permission:'All' } 
	},
	{ 
		path: '/register', name: 'Register', 
		component: Register,  meta: { unAuth: true, permission:'All' } 
	},
	{ 
		path: '/error', name: 'Error', 
		component: Error,  meta: { auth:true, permission:'All' } 
	},

	//Dashboard
	{ 
		path: '/', name: 'Home', 
		component: Dashboard,  meta: { auth: true, permission:'All' } 
	},
	{ 
		path: '/dashboard', name: 'Dashboard', 
		component: Dashboard,  meta: { auth: true, permission:'All' } 
	},
	{ 
		path: '/farm_nodes', name: 'Dashboard1', 
		component: oldDashboard,  meta: { auth: true, permission:'All' } 
	},
	{ 
		path: '/farm_node_data', name: 'Dashboard2', 
		component: oldDashboard1,  meta: { auth: true, permission:'All' } 
	},
	
	{ 
		path: '/profile', name: 'Profile', 
		component: Profile,  meta: { auth: true, permission:'All' } 
	},

	//Configuration
	{ 
		path: '/configuration', name: 'Configuration', 
		component: Configuration,  meta: { auth: true, permission:'configurations.view' } 
	},
	{ 
		path: '/roles', name: 'Roles', 
		component: Roles,  meta: { auth: true, permission:'roles.view' } 
	},
	{ 
		path: '/permissions', name: 'Permissions', 
		component: Permissions,  meta: { auth: true, permission:'permissions.view' } 
	},
	{ 
		path: '/crops', name: 'Crops', 
		component: Crops,  meta: { auth: true, permission:'crops.view'} 
	},
	{ 
		path: '/stages', name: 'Stagaes', 
		component: Stages,  meta: { auth: true, permission:'stages.view' } 
	},
	{ 
		path: '/actions', name: 'Actions', 
		component: Actions,  meta: { auth: true, permission:'actions.view' } 
	},
	{ 
		path: '/nodes', name: 'Nodes', 
		component: Nodes,  meta: { auth: true, permission:'nodes.view' } 
	},

	//Users
	{ 
		path: '/users', name: 'Users.Index', 
		component: Users,  meta: { auth: true, permission:'users.view' } 
	},
	{ 
		path: '/users/create', name: 'Users.Create', 
		component: CreateUser,  meta: { auth: true, permission:'users.create' } 
	},
	{ 
		path: '/users/:user_id/edit', name:'Users.Edit', 
		component:CreateUser,  meta: { auth: true, permission:'users.update' }
	},

	//Farms
	{ 
		path: '/farms', name: 'Farms.Index', 
		component: Farms,  meta: { auth: true, permission:'farms.view' } 
	},
	{ 
		path: '/farms/create', name: 'Farms.Create', 
		component: CreateFarm,  meta: { auth: true , permission:'farms.create'} 
	},
	{ 
		path: '/farms/:farm_id', name:'Farms.Show', 
		component:ShowFarm,  meta: { auth: true, permission:'farms.view' }
	},
	{ 
		path: '/farms/:farm_id/edit', name:'Farms.Edit', 
		component:CreateFarm,  meta: { auth: true, permission:'farms.update' }
	},
	{ 
		path: '/farms/:farm_id/images', name:'Farms.Images', 
		component:FarmImages,  meta: { auth: true, permission:'farm_images.view' }
	},

	//SubFarms
	{ 
		path: '/sub_farms', name: 'SubFarms.Index', 
		component: SubFarms,  meta: { auth: true, permission:'sub_farms.view' } 
	},
	{ 
		path: '/sub_farms/create', name: 'SubFarms.Create', 
		component: CreateSubFarm,  meta: { auth: true, permission:'sub_farms.create' } 
	},
	{ 
		path: '/sub_farms/:sub_farm_id/edit', name:'SubFarms.Edit', 
		component:CreateSubFarm,  meta: { auth: true, permission:'sub_farms.update' }
	},
	{ 
		path: '/sub_farms/:sub_farm_id', name: 'SubFarms.Show', 
		component: ShowSubFarm,  meta: { auth: true, permission:'sub_farms.view' } 
	},
	{ 
		path: '/sub_farms/:sub_farm_id/schedule', name: 'SubFarms.Schedule', 
		component: SubFarmsSchedule,  meta: { auth: true, permission:'schedules.view' } 
	},
	{ 
		path: '/sub_farms/:sub_farm_id/images', name:'SubFarms.Images', 
		component:SubFarmImages,  meta: { auth: true, permission:'farm_images.view' }
	},
	{ 
		path: '/sub_farms/:sub_farm_id/log', name: 'SubFarms.Log', 
		component: SubFarmsLog,  meta: { auth: true, permission:'logs.view' } 
	},
	
	//Pests
	{ 
		path: '/pests', name: 'Pests.Index', 
		component: Pests,  meta: { auth: true, permission:'pests.view' } 
	},
	{ 
		path: '/pests/create', name: 'Pests.Create', 
		component: CreatePest,  meta: { auth: true, permission:'pests.create' } 
	},
	{ 
		path: '/pests/:pest_id/edit', name:'Pests.Edit', 
		component:CreatePest,  meta: { auth: true, permission:'pests.update' }
	},
	{ 
		path: '/pests/:pest_id', name:'Pests.Show', 
		component:ShowPest,  meta: { auth: true, permission:'pests.view' }
	},

	//Disease
	{ 
		path: '/diseases', name: 'Diseases.Index', 
		component: Diseases,  meta: { auth: true, permission:'diseases.view' } 
	},
	{ 
		path: '/diseases/create', name: 'Diseases.Create', 
		component: CreateDisease,  meta: { auth: true, permission:'diseases.create' } 
	},
	{ 
		path: '/diseases/:disease_id/edit', name:'Diseases.Edit', 
		component:CreateDisease,  meta: { auth: true, permission:'diseases.update' }
	},
	{ 
		path: '/diseases/:disease_id', name:'Diseases.Show', 
		component:ShowDisease,  meta: { auth: true, permission:'diseases.view' }
	},

	//Spray
	{ 
		path: '/sprays', name: 'Sprays.Index', 
		component: Sprays,  meta: { auth: true, permission:'sprays.view' } 
	},
	{ 
		path: '/sprays/create', name: 'Sprays.Create', 
		component: CreateSpray,  meta: { auth: true, permission:'sprays.create' } 
	},
	{ 
		path: '/sprays/:spray_id/edit', name:'Sprays.Edit', 
		component:CreateSpray,  meta: { auth: true, permission:'sprays.update' }
	},

	// equipments
	{ 
		path: '/equipments', name: 'Equipments.Index', 
		component: Equipments,  meta: { auth: true, permission:'expenses.view' } 
	},
	{ 
		path: '/equipments/create', name: 'Equipments.Create', 
		component: CreateEquipment,  meta: { auth: true, permission:'expenses.create' } 
	},
	{ 
		path: '/equipments/:equipment_id/edit', name:'Equipments.Edit', 
		component:CreateEquipment,  meta: { auth: true, permission:'expenses.update' }
	},

	// labours
	{ 
		path: '/labours', name: 'Labours.Index', 
		component: Labours,  meta: { auth: true, permission:'expenses.view' } 
	},
	{ 
		path: '/labours/create', name: 'Labours.Create', 
		component: CreateLabour,  meta: { auth: true, permission:'expenses.create' } 
	},
	{ 
		path: '/labours/:labour_id/edit', name:'Labours.Edit', 
		component:CreateLabour,  meta: { auth: true, permission:'expenses.update' }
	},

	// seeds
	{ 
		path: '/seeds', name: 'Seeds.Index', 
		component: Seeds,  meta: { auth: true, permission:'expenses.view' } 
	},
	{ 
		path: '/seeds/create', name: 'Seeds.Create', 
		component: CreateSeed,  meta: { auth: true, permission:'expenses.create' } 
	},
	{ 
		path: '/seeds/:seed_id/edit', name:'Seeds.Edit', 
		component:CreateSeed,  meta: { auth: true, permission:'expenses.update' }
	},

	// maintainances
	{ 
		path: '/maintainances', name: 'Maintainances.Index', 
		component: Maintainances,  meta: { auth: true, permission:'expenses.view' } 
	},
	{ 
		path: '/maintainances/create', name: 'Maintainances.Create', 
		component: CreateMaintainance,  meta: { auth: true, permission:'expenses.create' } 
	},
	{ 
		path: '/maintainances/:maintainance_id/edit', name:'Maintainances.Edit', 
		component: CreateMaintainance,  meta: { auth: true, permission:'expenses.update' }
	},

	// Schedules
	{ 
		path: '/schedules', name: 'Schedules.Index', 
		component: Schedules,  meta: { auth: true, permission:'schedules.view' } 
	},
	{ 
		path: '/schedules/create', name: 'Schedules.Create', 
		component: CreateSchedule,  meta: { auth: true, permission:'schedules.create' } 
	},
	{ 
		path: '/schedules/:schedule_id/edit', name:'Schedules.Edit', 
		component: CreateSchedule,  meta: { auth: true, permission:'schedules.update' }
	},
	{ 
		path: '/schedules/:schedule_id', name:'Schedules.Show', 
		component: ShowSchedule,  meta: { auth: true, permission:'schedules.view' }
	},

	// Harvests
	{ 
		path: '/harvests', name: 'Harvests.Index', 
		component: Harvests,  meta: { auth: true, permission:'harvests.view' } 
	},
	{ 
		path: '/harvests/create', name: 'Harvests.Create', 
		component: CreateHarvest,  meta: { auth: true, permission:'harvests.create' } 
	},
	{ 
		path: '/harvests/:harvest_id/edit', name:'Harvests.Edit', 
		component: CreateHarvest,  meta: { auth: true, permission:'harvests.update' }
	},
	{ 
		path: '/harvests/:harvest_id', name:'Harvests.Show', 
		component: ShowHarvest,  meta: { auth: true, permission:'harvests.view' }
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach(function(to, _, next) {
	if (to.meta.auth && !store.getters.user) {
		next('/login');
	} 
	else if(to.meta.unAuth && store.getters.user) {
		next('/dashboard');
	}
	else {
		let permissions = store.getters.permissions;
		if(to.meta.permission=='All') {
			next();
		}
		else if(permissions.length!=0) {
			let permission = permissions.filter(function (el) {
				return el.permission == to.meta.permission || to.meta.permission=='All';
			});
			if (!permission[0]) {
				next('/error')
			}
			else {
				next();
			}
		}
		else {
			next('/error')
		}
	}
});

export default router
