<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-spray-can"></i>
							Spray Records
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link :to="'/sprays?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-secondary">
								<i class="fas fa-arrow-left"></i>
								Back
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
                                <strong v-if="status">New Spray Record</strong>
                                <strong v-else>Update Spray Record</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-6">
										<div class="form-group">
											<label class="form-label">Farm *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.farm_id}" v-model="spray.farm_id" ref="farm_id" @change="getSubFarms">
												<option value="">Select Farm</option>
												<option v-for="farm,key in farms" :key="key" :value="farm.farm_id">{{ farm.farm_name }}</option>
											</select>
											<span v-if="errors.farm_id" class="invalid-feedback">{{ errors.farm_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<label class="form-label">Crop *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.sub_farm_id}" v-model="spray.sub_farm_id">
												<option value="">Select SubFarm</option>
												<option v-for="sub_farm,key in sub_farms" :key="key" :value="sub_farm.sub_farm_id">{{ sub_farm.crop.crop_name }}</option>
											</select>
											<span v-if="errors.sub_farm_id" class="invalid-feedback">{{ errors.sub_farm_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Date *</label>
											<input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.date}" placeholder="Date" v-model="spray.date">
											<span v-if="errors.date" class="invalid-feedback">{{ errors.date[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Time *</label>
											<input type="time" class="form-control form-control-sm" :class="{'is-invalid': errors.time}" placeholder="Time" v-model="spray.time">
											<span v-if="errors.time" class="invalid-feedback">{{ errors.time[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Operator *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.operator}" placeholder="Operator" v-model="spray.operator">
											<span v-if="errors.operator" class="invalid-feedback">{{ errors.operator[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Dosage *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.dosage}" placeholder="Dosage" v-model="spray.dosage">
											<span v-if="errors.dosage" class="invalid-feedback">{{ errors.dosage[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Bio Chemical Name *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.bio_chemical_name}" placeholder="Bio Chemical Name" v-model="spray.bio_chemical_name">
											<span v-if="errors.bio_chemical_name" class="invalid-feedback">{{ errors.bio_chemical_name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Application Rate *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.application_rate}" placeholder="Application Rate" v-model="spray.application_rate">
											<span v-if="errors.application_rate" class="invalid-feedback">{{ errors.application_rate[0] }}</span>
										</div>
									</div>
									<div class="col-sm-12">
										<div class="form-group">
											<label class="form-label">Note</label>
											<textarea class="form-control form-control-sm" :class="{'is-invalid': errors.note}" placeholder="Note" v-model="spray.note"></textarea>
											<span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
										</div>
									</div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link :to="'/sprays?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-outline-danger" type="button">
                                        <i class="fas fa-ban"></i> Discard
                                    </router-link>
                                    <button class="btn btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'Sprays.Create',
		data(){
            return{
                status:true,
                spray:{
                    spray_id:'',
					farm_id:'',
					sub_farm_id:'',
					date:new Date().toISOString().slice(0,10),
					time:new Date().toISOString().slice(11,16),
					user_id:'',
					operator:'',
					dosage:'',
					bio_chemical_name:'',
					application_rate:'',
					note:'',
				},
				farm_id:'',
				sub_farm_id:'',
				user_id:this.$store.state.user.user_id,
				role:this.$store.state.user.role,
				farms:[],
				sub_farms:[],
				errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.farm_id = to.query.farm_id;
				vm.sub_farm_id = to.query.sub_farm_id;
				vm.spray.farm_id = vm.farm_id;
				vm.spray.sub_farm_id = vm.sub_farm_id;
				if(to.name=='Sprays.Create') {
					vm.getFarms();
					vm.spray.user_id = vm.$store.getters.user.user_id;
					vm.$refs.farm_id.focus();
				}
				else {
					let loader = vm.$loading.show();
					let uri = {'uri':'sprays/'+to.params.spray_id};
					vm.$store.dispatch('get',uri)
					.then(function (response) {
						loader.hide();
						vm.getFarms();
						vm.spray = response.data.data;
						vm.status = false;
						vm.$refs.name.focus()
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			});
        },
		methods:{

			getFarms() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'farms?user_id='+vm.user_id+'&role='+vm.role};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.farms = response.data.data;
					vm.getSubFarms();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getSubFarms() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'sub_farms?farm_id='+vm.spray.farm_id,'data':vm.spray};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.sub_farms = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'sprays','data':vm.spray};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Spray record is successfully created');
					vm.$router.push('/sprays/?farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(spray){
                let vm = this;
                vm.spray = spray,
                vm.status = false,
                vm.$refs.spray.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'sprays/'+vm.spray.spray_id,'data':vm.spray};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Spray record is successfully updated');
					vm.$router.push('/sprays/?farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            }
		}
	}
</script>
