import { createStore } from 'vuex'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import axios from 'axios';
import moment from 'moment';

export default createStore({
	state: {
		apiUrl: "https://api.growtogether.in/api/",
		assetUrl: "https://api.growtogether.in/storage/",
		// apiUrl: "http://localhost/k2farms/laravel/public/api/",
		// assetUrl: "http://localhost/k2farms/laravel/public/storage/",
		user:null,
		permissions:[],
	},
	
	mutations: {
		setAuthentication(state, data){
			state.authenticated = data;
		},
		setUser(state, user){
			state.user = user;
		},
		setUrl(state, url){
			state.apiUrl = url;
		},
		setPermissions(state, permissions){
			state.permissions = permissions;
		},
	},

	getters: {
		user(state){
			return state.user;
		},
		permissions(state){
			return state.permissions;
		},
		apiUrl(state){
			return state.apiUrl;
		},
		assetUrl(state){
			return state.assetUrl;
		},
	},

	actions: {
		async setUser(context,payload) {
			localStorage.setItem('user',JSON.stringify(payload))
			await context.commit('setUser',payload);
		},

		async setPermissions(context,payload) {
			localStorage.setItem('permissions',JSON.stringify(payload))
			await context.commit('setPermissions',payload);
		},

		async autoLogin(context) {
			const user = JSON.parse(localStorage.getItem('user'))
			const permissions = JSON.parse(localStorage.getItem('permissions'))
			if(user && permissions) {
				await context.commit('setUser',user);
				await context.commit('setPermissions',permissions);
			}
		},

		async logout({commit}) {
			localStorage.removeItem('user');
			localStorage.removeItem('permissions');
			await commit('setUser', null);
			await commit('setPermissions', []);
		},

		auth(context,payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl+payload.uri,payload.data)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		get(context,payload) {
			return new Promise((resolve, reject) => {
				axios.get(this.state.apiUrl+payload.uri)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		post(context,payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl+payload.uri,payload.data)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		patch(context,payload) {
			return new Promise((resolve, reject) => {
				axios.patch(this.state.apiUrl+payload.uri,payload.data)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		delete(context,payload) {
			return new Promise((resolve, reject) => {
				axios.delete(this.state.apiUrl+payload.uri)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		upload(context,payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl+payload.uri,payload.data, {
					headers: {
							'Content-Type': 'multipart/form-data'
					}
				}).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		async error(context,description) {
			await createToast({
				title: 'Error',
				description: description || "The given data was invalid."
			},
			{
				showIcon: true,
				hideProgressBar: true,
				type: 'danger',
				position: 'top-right'
			})
		},

		async success(context,description) {
			await createToast({
				title: 'Success',
				description: description || "Data is successfuly subbmited."
			},
			{
				showIcon: true,
				hideProgressBar: true,
				type: 'success',
				position: 'top-right'
			})
		},

		convertDate(context,value) {
			return moment(value).format('DD-MM-YYYY');
		},

		convertTime(context,value) {
			return moment(value).format('HH:MM A');
		}
	}
})
