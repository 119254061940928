<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-pastafarianism"></i>
							Disease Records
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link :to="'/sub_farms/'+sub_farm_id+'?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-secondary">
								<i class="fas fa-arrow-left"></i>
								Back
							</router-link>
							<router-link v-can="'diseases.create'" :to="'/diseases/create?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-primary">
								<i class="fa fa-plus"></i>
								New Record
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
						<div class="card">
                            <div class="card-header">
								<div class="card-title">
									<i class="fas fa-book"></i>
									Disease Records
								</div>
								<div class="card-tools">
									<input class="form-control form-control-sm search" type="text" placeholder="Search" v-model="meta.search" @keyup="index" ref="search">
								</div>
                            </div>
                            <div class="card-body">
                                <table class="table table-responsive-sm  table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
											<th>Farm</th>
											<th>SubFarm</th>
											<th>Crop</th>
											<th>Date</th>
											<th>Time</th>
											<th>Bed / Tower</th>
											<th>Disease Name</th>
											<th>Disease Stage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="cursor" v-for="(disease,key) in diseases" :key="key" @click="show(disease)">
                                            <td class="text-center">{{ meta.from+key }}</td>
											<td>{{ disease.farm.farm_name }}</td>
											<td>{{ disease.sub_farm.sub_farm_name }}</td>
											<td>{{ disease.sub_farm.crop.crop_name }}</td>
											<td>{{ disease.date }}</td>
											<td>{{ disease.time }}</td>
											<td>{{ disease.bed_tower_no }}</td>
											<td>{{ disease.disease_name }}</td>
											<td>{{ disease.disease_stage }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer clearfix">
								<select class="form-control form-control-sm per_page float-left" type="text" placeholder="Search" v-model="meta.per_page" @change="onPerPageChange">
									<option>10</option>
									<option>15</option>
									<option>20</option>
									<option>25</option>
									<option>30</option>
								</select>
								<Pagination
									:maxPage="meta.maxPage"
									:totalPages="meta.lastPage"
									:currentPage="meta.currentPage"
									@pagechanged="onPageChange"
								/>
							</div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination.vue';
	export default {
		name: 'Diseases.Index',
		components: { Pagination },
		data(){
            return{
				meta: {
                    search:'',
					order_by:'desc',
					keyword:'disease_id',
					per_page:10,
                    totalRows:0,
                    currentPage:1,
                    lastPage:1,
                    from: 1,
                    maxPage:1,
                },
				farm_id:'',
				sub_farm_id:'',
				user_id:this.$store.state.user.user_id,
				diseases:[],
                errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.farm_id = to.query.farm_id;
				vm.sub_farm_id = to.query.sub_farm_id;
				vm.index();
			});
        },
		methods:{

			index() {
				let vm = this;
				let uri = 'diseases?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&keyword='+vm.meta.keyword+'&per_page='+vm.meta.per_page+'&farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id;
				vm.$store.dispatch('get',{'uri':uri})
				.then(function (response) {
					vm.diseases = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			show(disease){
                this.$router.push('/diseases/'+disease.disease_id+'?farm_id='+this.farm_id+'&sub_farm_id='+this.sub_farm_id)
            },

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            }
		}
	}
</script>
