<template>
	<div class="content-wrapper">
        <section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-book"></i>
								Pest Records
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link :to="'/pests?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-secondary">
                        <i class="fas fa-arrow-left"></i>
                        Back
                            </router-link>
                            <button v-can="'pests.create'" class="btn btn-primary" @click="status=true">
                                <i class="fas fa-upload"></i> Upload
                            </button>
                            <router-link v-can="'pests.update'" :to="'/pests/'+pest.pest_id+'/edit?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-success">
                                <i class="fas fa-edit"></i> Edit
                            </router-link>
                            <button v-can="'pests.delete'" class="btn btn-danger" @click="destroy"> 
                                <i class="far fa-trash-alt"></i> Delete
                            </button>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-4">
						<div class="card">
                            <div class="card-header">
                                <div class="card-title">
                                    <i class="fas fa-book"></i>
                                    Pest Record
                                </div>
                            </div>
                            <div class="card-body">
                                <table class="table table-responsive-sm  table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr>
                                            <td>Farm</td>
                                            <td v-if="pest.farm">
                                                {{ pest.farm.farm_name }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>SubFarm</td>
                                            <td v-if="pest.sub_farm">
                                                {{ pest.sub_farm.sub_farm_name }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Crop</td>
                                            <td v-if="pest.sub_farm">
                                                {{ pest.sub_farm.crop.crop_name }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Date</td>
                                            <td>
                                                {{ pest.date }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Time</td>
                                            <td>
                                                {{ pest.time }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>User</td>
                                            <td v-if="pest.user">
                                                {{ pest.user.name }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Bed / Tower No</td>
                                            <td>
                                                {{ pest.bed_tower_no }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Pest Name</td>
                                            <td>
                                                {{ pest.pest_name }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Pest Stage</td>
                                            <td>
                                                {{ pest.pest_stage }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Note</td>
                                            <td>
                                                {{ pest.note }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
					</div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">
                                    <i class="far fa-image"></i>
                                    Pest Images
                                </div>
                            </div>
                            <div class="card-body row">
                                <div class="col-sm-4" v-for="file,key in pest.files" :key="key">
                                    <div class="card text-center">
                                        <a :href="$store.getters.assetUrl+'files/'+file.file" data-lightbox="example-2"><img class="img img-thumbnail" :src="$store.getters.assetUrl+'files/'+file.file"></a>
                                        <div class="card-footer">
                                            <button v-can="'pests.delete'" class="btn btn-sm btn-outline-danger" @click="destroyFile(file,key)">
                                                <i class="far fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Comments
                            :user_id="$store.getters.user.user_id"
                            :module_id="pest.pest_id"
                            :module="'Pest'"
                            :comments="pest.comments"
                        />
                    </div>
				</div> 
			</div>
		</div>
        <FileModal
            :module_id="pest.pest_id"
            :module="'Pest'"
            :status="status"
            @onUploadFile="onUploadFile"
            @onStatusChange="onStatusChange"
        />
	</div>

</template>

<script>
    import FileModal from '@/components/FileModal.vue';
    import Comments from '@/components/Comments.vue';
	export default {
		name: 'pests.Show',
        components: { FileModal, Comments },
		data(){
            return{
                status:false,
                farm_id:'',
				sub_farm_id:'',
				pest:{},
                errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
                vm.farm_id = to.query.farm_id;
				vm.sub_farm_id = to.query.sub_farm_id;
				let loader = vm.$loading.show();
                let uri = {'uri':'pests/'+to.params.pest_id};
                vm.$store.dispatch('get',uri)
                .then(function (response) {
                    loader.hide();
                    vm.pest = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error',error.response.data.message);
                });
			});
        },
		methods:{
            destroy() {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'pests/'+vm.pest.pest_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Pest record is successfully deleted');
                        vm.$router.push('/pests/?farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id);
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

            onUploadFile(file) {
                this.status = false;
                this.pest.files.push(file)
            },

            onStatusChange(status) {
                this.status = status;
            },

            destroyFile(file,key) {
                let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'files/'+file.file_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Image is successfully deleted');
                        vm.pest.files.splice(key, 1);
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
            }
		}
	}
</script>
