<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h1>
							<i class="fas fa-seedling"></i>
							<span v-if="status"> New Sub Farm</span>
							<span v-else> Update Sub Farm</span>
						</h1>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link :to="'/farms/'+farm_id" class="btn btn-secondary">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
                                <strong v-if="status">New Sub Farm</strong>
                                <strong v-else>Update Sub Farm</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Farm *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.farm_id}" v-model="sub_farm.farm_id" ref="farm_id">
												<option value="">Select Farm</option>
												<option v-for="farm,key in farms" :key="key" :value="farm.farm_id">{{ farm.farm_name }}</option>
											</select>
											<span v-if="errors.farm_id" class="invalid-feedback">{{ errors.farm_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Crop *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.crop_id}" v-model="sub_farm.crop_id">
												<option value="">Select Crop</option>
												<option v-for="crop,key in crops" :key="key" :value="crop.crop_id">{{ crop.crop_name }}</option>
											</select>
											<span v-if="errors.crop_id" class="invalid-feedback">{{ errors.crop_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Sub Farm Name *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.sub_farm_name}" placeholder="Sub Farm Name" v-model="sub_farm.sub_farm_name">
											<span v-if="errors.sub_farm_name" class="invalid-feedback">{{ errors.sub_farm_name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Area (Acres)</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.area}" placeholder="Area (Acres)" v-model="sub_farm.area">
											<span v-if="errors.area" class="invalid-feedback">{{ errors.area[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Cultivation Type *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.cultivation_type}" v-model="sub_farm.cultivation_type">
												<option value="">Select Cultivation Type</option>
												<option>Soil</option>
												<option>Hydroponics</option>
												<option>Aeroponics</option>
											</select>
											<span v-if="errors.cultivation_type" class="invalid-feedback">{{ errors.cultivation_type[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">No of Plants *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.no_of_plants}" placeholder="No of Plants" v-model="sub_farm.no_of_plants">
											<span v-if="errors.no_of_plants" class="invalid-feedback">{{ errors.no_of_plants[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Date of Sowing *</label>
											<input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.date_of_sowing}" placeholder="Date of Sowing" v-model="sub_farm.date_of_sowing">
											<span v-if="errors.date_of_sowing" class="invalid-feedback">{{ errors.date_of_sowing[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Seed Company Name *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.seed_company_name}" placeholder="Seed Company Name" v-model="sub_farm.seed_company_name">
											<span v-if="errors.seed_company_name" class="invalid-feedback">{{ errors.seed_company_name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Seed Variety *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.seed_variety}" placeholder="Seed Variety" v-model="sub_farm.seed_variety">
											<span v-if="errors.seed_variety" class="invalid-feedback">{{ errors.seed_variety[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Expected Tonnage *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.expected_tonnage}" placeholder="Expected Tonnage" v-model="sub_farm.expected_tonnage">
											<span v-if="errors.expected_tonnage" class="invalid-feedback">{{ errors.expected_tonnage[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Nursing Source</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.nursing_source}" placeholder="Nursing Source" v-model="sub_farm.nursing_source">
											<span v-if="errors.nursing_source" class="invalid-feedback">{{ errors.nursing_source[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Harvest From *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.harvest_from}" v-model="sub_farm.harvest_from">
												<option value="">Select Harvest From</option>
												<option>Jan</option>
												<option>Feb</option>
												<option>March</option>
												<option>April</option>
												<option>May</option>
												<option>June</option>
												<option>July</option>
												<option>Aug</option>
												<option>Sep</option>
												<option>Oct</option>
												<option>Nov</option>
												<option>Dec</option>
											</select>
											<span v-if="errors.harvest_from" class="invalid-feedback">{{ errors.harvest_from[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Transplantation Date *</label>
											<input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.transplantation_date}" placeholder="Transplantation Date" v-model="sub_farm.transplantation_date">
											<span v-if="errors.transplantation_date" class="invalid-feedback">{{ errors.transplantation_date[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Completion Date</label>
											<input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.completion_date}" placeholder="Completion Date" v-model="sub_farm.completion_date">
											<span v-if="errors.completion_date" class="invalid-feedback">{{ errors.completion_date[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Water Source</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.water_source}" placeholder="Water Source" v-model="sub_farm.water_source">
											<span v-if="errors.water_source" class="invalid-feedback">{{ errors.water_source[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Row Orientation</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.row_orientation}" v-model="sub_farm.row_orientation">
												<option value="">Select Row Orientation</option>
												<option>North to South</option>
												<option>East to West</option>
											</select>
											<span v-if="errors.row_orientation" class="invalid-feedback">{{ errors.row_orientation[0] }}</span>
										</div>
									</div>
									<div class="col-sm-12">
										<div class="form-group">
											<label class="form-label">Infrastructure of the Plant</label>
											<textarea class="form-control form-control-sm" :class="{'is-invalid': errors.infrastructure}" placeholder="Infrastructure" v-model="sub_farm.infrastructure"></textarea>
											<span v-if="errors.infrastructure" class="invalid-feedback">{{ errors.infrastructure[0] }}</span>
										</div>
									</div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link :to="'/farms/'+farm_id" class="btn btn-outline-danger" type="button">
                                        <i class="fas fa-ban"></i> Discard
                                    </router-link>
                                    <button class="btn btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'SubFarms.Create',
		data(){
            return{
                status:true,
                sub_farm:{
                    sub_farm_id:'',
					farm_id:'',
					crop_id:'',
					sub_farm_name:'',
					area:'',
					cultivation_type:'',
					no_of_plants:'',
					date_of_sowing:'',
					seed_company_name:'',
					seed_variety:'',
					expected_tonnage:'',
					nursing_source:'',
					harvest_from:'',
					transplantation_date:'',
					water_source:'',
					infrastructure:'',
					row_orientation:''
				},
				farm_id:'',
				user_id:this.$store.state.user.user_id,
				role:this.$store.state.user.role,
				farms:[],
				crops:[],
				errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.farm_id = to.query.farm_id;
				vm.sub_farm.farm_id = to.query.farm_id;
				if(to.name=='SubFarms.Create') {
					vm.getFarms();
					vm.$refs.farm_id.focus();
				}
				else {
					let uri = {'uri':'sub_farms/'+to.params.sub_farm_id};
					vm.$store.dispatch('get',uri)
					.then(function (response) {
						vm.getFarms();
						vm.sub_farm = response.data.data;
						vm.status = false;
						vm.$refs.name.focus()
					})
					.catch(function (error) {
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			});
        },
		methods:{

			getFarms() {
				let vm = this;
				let loader = vm.$loading.show();
				
                let uri = {'uri':'farms?user_id='+vm.user_id+'&role='+vm.role,'data':vm.sub_farm};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.farms = response.data.data;
					vm.getCrops();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getCrops() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'crops','data':vm.sub_farm};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.crops = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'sub_farms','data':vm.sub_farm};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','SubFarm is successfully created');
					vm.$router.push('/farms/'+vm.farm_id);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(sub_farm){
                let vm = this;
                vm.sub_farm = sub_farm,
                vm.status = false,
                vm.$refs.sub_farm.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'sub_farms/'+vm.sub_farm.sub_farm_id,'data':vm.sub_farm};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','SubFarm is successfully updated');
					vm.$router.push('/farms/'+vm.farm_id);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            }
		}
	}
</script>
