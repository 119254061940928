<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-seedling"></i>
							Crops
						</h3>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-4" v-can="'crops.create'">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
                                <div class="card-title">
									<i class="fas fa-seedling"></i>
									<span v-if="status"> New Crop</span>
									<span v-else> Update Crop</span>
								</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="crop">Crop Name *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.crop_name}" type="text" v-model="crop.crop_name" placeholder="Crop Name" ref="crop_name">
                                            <span v-if="errors.crop_name" class="invalid-feedback">{{ errors.crop_name[0] }}</span>
                                        </div>
                                    </div>
									<div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="crop">Crop Family *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.crop_family}" type="text" v-model="crop.crop_family" placeholder="Crop Family">
                                            <span v-if="errors.crop_family" class="invalid-feedback">{{ errors.crop_family[0] }}</span>
                                        </div>
                                    </div>
									<div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="crop">Crop Cycle *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.crop_cycle}" type="text" v-model="crop.crop_cycle" placeholder="Crop Cycle">
                                            <span v-if="errors.crop_cycle" class="invalid-feedback">{{ errors.crop_cycle[0] }}</span>
                                        </div>
                                    </div>
									<div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="crop">Crop Image *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.crop_image}" type="file" @change="onImageChange($event)" accept="image/*">
                                            <span v-if="errors.crop_image" class="invalid-feedback">{{ errors.crop_image[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="description">Description</label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.description}" type="text" v-model="crop.description" placeholder="Description"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button  class="btn btn-outline-danger" type="button" @click="discard">
                                        <i class="fas fa-ban"></i> Discard
                                    </button>
                                    <button class="btn btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
					<div class="col-sm-8">
						<div class="card">
                            <div class="card-header">
								<div class="card-title">
									<i class="fas fa-seedling"></i>
									Crops
								</div>
								<div class="card-tools">
									<input class="form-control form-control-sm search" type="text" placeholder="Search" v-model="meta.search" @keyup="index" ref="search">
								</div>
                            </div>
                            <div class="card-body">
                                <table class="table table-responsive-sm  table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Crop Name</th>
                                            <th>Crop Family</th>
											<th>Crop Cycle</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(crop,key) in crops" :key="key">
                                            <td class="text-center">{{ meta.from+key }}</td>
											<td>
												<img :src="$store.getters.assetUrl+'crops/'+crop.crop_image" class="crop-img">
												{{ crop.crop_name }}
											</td>
                                            <td>{{ crop.crop_name }}</td>
                                            <td>{{ crop.crop_family }}</td>
											<td>{{ crop.crop_cycle }}</td>
                                            <td class="text-center">
												<button v-can="'crops.update'" class="btn btn-xs btn-outline-success" @click="edit(crop)">
                                                    <i class="far fa-edit"></i>
                                                </button>
                                                <button v-can="'crops.delete'" class="btn btn-xs btn-outline-danger" @click="destroy(crop)">
                                                    <i class="far fa-trash-alt"></i>
                                                </button>
											</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer clearfix">
								<select class="form-control form-control-sm per_page float-left" type="text" placeholder="Search" v-model="meta.per_page" @change="onPerPageChange">
									<option>5</option>
									<option>10</option>
									<option>15</option>
									<option>20</option>
									<option>25</option>
								</select>
								<Pagination
									:maxPage="meta.maxPage"
									:totalPages="meta.lastPage"
									:currentPage="meta.currentPage"
									@pagechanged="onPageChange"
								/>
							</div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination.vue';
	export default {
		name: 'Home',
		components: { Pagination },
		data(){
            return{
                status:true,
                crop:{
                    crop_id:'',
                    crop_name:'',
                    crop_family:'',
					crop_cycle:'',
					crop_image:'',
                    description:'',
                },
				meta: {
                    search:'',
					order_by:'asc',
					keyword:'crop_name',
					per_page:5,
                    totalRows:0,
                    currentPage:1,
                    lastPage:1,
                    from: 1,
                    maxPage:1,
                },
				crops:[],
                errors:[]
            }
        },
		mounted() {
            let vm = this;
            vm.discard();
        },
		methods:{
			discard(){
                let vm = this;
                vm.errors=[],
                vm.crop.crop_id='',
                vm.crop.crop_name='',
				vm.crop.crop_family='',
				vm.crop.crop_cycle='',
                vm.crop.description='',
                vm.status=true,
                vm.$refs.crop_name.focus(),
                vm.index()
            },

			onImageChange(e) {
                e.preventDefault();
                let vm = this;
                let image = e.target.files[0]; 
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (e) => {
                    vm.crop.crop_image = e.target.result;
                };
            },

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			index() {
				let vm = this;
				let uri = 'crops?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&keyword='+vm.meta.keyword+'&per_page='+vm.meta.per_page;
				vm.$store.dispatch('get',{'uri':uri})
				.then(function (response) {
					vm.crops = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('post',{'uri':'crops','data':vm.crop})
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Crop is successfully created');
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(crop){
                let vm = this;
                vm.crop = crop,
                vm.status = false,
                vm.$refs.crop_name.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'crops/'+vm.crop.crop_id,'data':vm.crop};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Crop is successfully updated');
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			destroy(crop) {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'crops/'+crop.crop_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Crop is successfully deleted');
						vm.discard();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

			onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            }
		}
	}
</script>
