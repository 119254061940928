<template>
    <div v-if="status">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-md" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    <i class="fas fa-microchip"></i>
                                    IoT Nodes
                                </h5>
                                <button type="button" class="close" @click="closeModal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <select class="form-control form-control-sm" :class="{'is-invalid': errors.node_id}" v-model="node_id" ref="node_id">
                                    <option value="">Select Node</option>
                                    <option v-for="node,key in nodes" :key="key" :value="node.node_id">{{ node.display_name }} - {{ node.node_name }}</option>
                                </select>
                                <span v-if="errors.node_id" class="invalid-feedback">{{ errors.node_id[0] }}</span>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-sm btn-outline-danger" @click="closeModal">
                                    <i class="fas fa-ban"></i> Discard
                                </button>
                                <button type="button" class="btn btn-sm btn-outline-success" @click="saveNode">
                                   <i class="fas fa-save"></i> Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name:'Modal',
        props: {
            farm_id: {
                type: Number,
                required: true
            },
            sub_farm_id: {
                type: Number,
                required: true
            },
            status: {
                type: Boolean,
                required: true
            },
        },
        data(){
            return{
                node_id:'',
                nodes:[],
                errors:[]
            }
        },
        created(){
            this.getNodes()
        },
        methods: {
            closeModal() {
                this.node_id = "";
                this.$emit('onStatusChange', false);    
            },

            getNodes() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'nodes'};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.nodes = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

            saveNode() { 
                let vm = this;
				let loader = vm.$loading.show();
                let data = {
                    'farm_id':vm.farm_id,
                    'sub_farm_id':vm.sub_farm_id,
                    'node_id':vm.node_id,
                }
				let uri = {'uri':'farm_nodes','data':data};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch('success','Node is successfully assigned');
                    vm.node_id = "";
                    vm.$emit('onSaveNode',response.data.data)
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
            }
        }
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
</style>