<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-8">
						<h1 v-if="sub_farm.farm">
							<i class="fas fa-seedling"></i>
							{{ sub_farm.farm.farm_name }}
                            | <small>{{ sub_farm.crop.crop_name }}</small>
						</h1>
					</div>
					<div class="col-sm-4">
						<div class="float-sm-right">
							<router-link :to="'/sub_farms/'+sub_farm.sub_farm_id+'?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-secondary">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
                            <button v-can="'pests.create'" class="btn btn-success" @click="addToPest">
								<i class="fa fa-plus"></i> Add to Pest
							</button>
                            <button v-can="'diseases.create'" class="btn btn-success" @click="addToDisease">
								<i class="fa fa-plus"></i> Add to Disease
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
        <div class="content" v-if="sub_farm.farm">
			<div class="container">
				<div class="row">
                    <div class="col-sm-4" v-can="'farm_images.create'">
                        <div class="card">
							<div class="card-header clearfix">
								<div class="card-title">
									<i class="fas fa-image"></i>
									Upload Images 
								</div>
							</div>
							<div class="card-body">
								<form class="row" @submit.prevent="uploadImages">
									<div class="col-sm-12">
										<div class="form-group">
											<input multiple type="file" class="form-control form-control-sm" :class="{'is-invalid': errors.images}" @change="handleImagesUpload($event)" ref="images" accept="image/*">
											<span v-if="errors.images" class="invalid-feedback">{{ errors.images[0] }}</span>
										</div>
									</div>
									<div class="col-sm-12 text-center">
										<button type="submit" class="btn btn-primary"> Upload</button>
									</div>
								</form>
							</div>
						</div>
                    </div>
					<div class="col-sm-8" v-can="'farm_images.view'">
                        <div class="card">
							<div class="card-header clearfix">
								<div class="card-title">
									<i class="fas fa-image"></i>
									Images 
								</div>
							</div>
							<div class="card-body row">
								<div class="col-sm-4" v-for="file,key in files" :key="key">
									<div class="card text-center">
										<a :href="$store.getters.assetUrl+'files/'+file.file" data-lightbox="example-2"><img class="img img-thumbnail" :src="$store.getters.assetUrl+'files/'+file.file"></a>
										<div class="card-footer">
                                            <input v-can="'farm_images.update'" type="checkbox"  v-model="file.is_checked">
											<button v-can="'farm_images.delete'" class="btn btn-outline-danger" @click="destroyFile(file)">
												<i class="far fa-trash-alt"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
                            <div class="card-footer clearfix">
                                <select class="form-control form-control-sm per_page float-left" type="text" placeholder="Search" v-model="meta.per_page" @change="onPerPageChange">
                                    <option>9</option>
                                    <option>12</option>
                                    <option>15</option>
                                    <option>18</option>
                                    <option>21</option>
                                </select>
                                <Pagination
                                    :maxPage="meta.maxPage"
                                    :totalPages="meta.lastPage"
                                    :currentPage="meta.currentPage"
                                    @pagechanged="onPageChange"
                                />
                            </div>
						</div>
                    </div>
				</div> 
			</div>
		</div>
	</div>

</template>
<script>
    import Pagination from '@/components/Pagination.vue';
    export default {
		name: 'sub_farms.images',
        components: { Pagination },
		data(){
			return{
                farm_id:'',
				sub_farm_id:'',
				sub_farm:{},
                files:[],
                meta: {
                    search:'',
					order_by:'asc',
					keyword:'created_at',
					per_page:9,
                    totalRows:0,
                    currentPage:1,
                    lastPage:1,
                    from: 1,
                    maxPage:1,
                },
                status:false,
                images:[],
				errors:[]
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
                vm.farm_id = to.query.farm_id;
				vm.sub_farm_id = to.query.sub_farm_id;
				let loader = vm.$loading.show();
				let uri = {'uri':'sub_farms/'+to.params.sub_farm_id};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.sub_farm = response.data.data;
                    vm.getImages()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			});
		},
		methods:{
            handleImagesUpload(e) {
                e.preventDefault();
                let vm = this;
                vm.images = [];
                let files = vm.$refs.images.files;
                for(let i = 0; i<files.length; i++) {
                    let image = files[i]; 
                    let reader = new FileReader();
                    reader.readAsDataURL(image);
                    reader.onload = (e) => {
                        vm.images.push(e.target.result);
                    };
                }
            },

            getImages() {
				let vm = this;
				let uri = 'files?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&keyword='+vm.meta.keyword+'&per_page='+vm.meta.per_page+'&farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id;
				vm.$store.dispatch('get',{'uri':uri})
				.then(function (response) {
					vm.files = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

            uploadImages() {
				let vm = this;
				let loader = vm.$loading.show();
                let data = {
                    farm_id:vm.farm_id,
                    sub_farm_id:vm.sub_farm_id,
                    images:vm.images,
                }
				vm.$store.dispatch('post',{'uri':'upload_images','data':data})
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Images are successfully uploaded');
                    vm.$refs.images.value=null;
                    vm.getImages()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
            
            destroyFile(file) {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'files/'+file.file_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Image is successfully deleted');
						vm.getImages()
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

            onPageChange(page) {
                this.meta.currentPage = page;
                this.getImages();
            },

			onPerPageChange() {
                this.meta.currentPage = 1;
                this.getImages();
            },

			addToPest() {
				let vm = this;
				let files = vm.files.filter(function (el) {
					return el.is_checked == true
				});
				if(files.length!=0) {
					vm.$router.push('/pests/create/?farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id+'&files='+JSON.stringify(files)+'&copy=Yes');
				}
				else {
					alert('Please select images to continue')
				}
			},

			addToDisease() {
				let vm = this;
				let files = vm.files.filter(function (el) {
					return el.is_checked == true
				});
				if(files.length!=0) {
					vm.$router.push('/diseases/create/?farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id+'&files='+JSON.stringify(files)+'&copy=Yes');
				}
				else {
					alert('Please select images to continue')
				}
			}
		}
	}
</script>