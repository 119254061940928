<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-calendar-alt"></i>
							Schedules
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link :to="'/sub_farms/'+sub_farm_id+'?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-secondary">
								<i class="fas fa-arrow-left"></i>
								Back
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-12" v-can="'schedules.view'">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
                                <strong>Schedule</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-12">
                                        <table class="table table-responsive-sm table-bordered table-striped table-sm" v-can="'schedules.create'">
                                            <thead>
                                                <tr>
                                                    <th width="12%" class="text-center">
                                                        Day
                                                    </th>
                                                    <th width="15%" class="text-center">
                                                        Status
                                                    </th>
                                                    <th width="18%" class="text-center">
                                                        Action
                                                    </th>
                                                    <th width="30%" class="text-center">
                                                       {{ description }}
                                                    </th>
                                                    <th width="20%" class="text-center">
                                                        Dosage
                                                    </th>
                                                    <th width="5%" class="text-center">
                                                        <i class="fas fa-exchange-alt"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
												<tr>
													<td>
														<input class="form-control form-control-sm" :class="{'is-invalid': errors.day}" type="number" v-model="schedule.day" placeholder="Day" ref="day">
                                                        <div v-if="errors.day" class="invalid-feedback">{{ errors.day[0] }}</div>
													</td>
													<td>
														<select class="form-control form-control-sm" :class="{'is-invalid': errors.stage_id}" v-model="schedule.stage_id">
															<option value="">Select Stage</option>
															<option v-for="stage,key in stages" :key="key" :value="stage.stage_id">{{ stage.stage_name }}</option>
														</select>
                                                        <div v-if="errors.stage_id" class="invalid-feedback">{{ errors.stage_id[0] }}</div>
													</td>
													<td>
														<select class="form-control form-control-sm" :class="{'is-invalid': errors.action_id}" v-model="schedule.action_id" @change="getDescription($event)">
															<option value="">Select Action</option>
															<option v-for="action,key in actions" :key="key" :value="action.action_id">{{ action.action_name }}</option>
														</select>
                                                        <div v-if="errors.action_id" class="invalid-feedback">{{ errors.action_id[0] }}</div>
													</td>
													<td>
														<input class="form-control form-control-sm" :class="{'is-invalid': errors.description}" type="text" v-model="schedule.description" :placeholder="place_holder">
                                                        <div v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</div>
													</td>
													<td>
														<input class="form-control form-control-sm" :class="{'is-invalid': errors.dosage}" type="text" :readonly="isReadOnly" v-model="schedule.dosage" placeholder="Dosage">
                                                        <div v-if="errors.dosage" class="invalid-feedback">{{ errors.dosage[0] }}</div>
													</td>
													<td class="text-center">
														<button v-if="particular.state" class="btn btn-xs btn-outline-success" type="button" @click="create">
                                                            <i class="fas fa-plus"></i>
                                                        </button>
                                                        <button v-else class="btn btn-xs btn-outline-success" type="button" @click="update">
                                                            <i class="fas fa-redo-alt"></i>
                                                        </button>
													</td>
												</tr>
											</tbody>
										</table>
										<table class="table table-responsive-sm table-bordered table-sm">
											<thead>
                                                <tr>
                                                    <th width="12%" class="text-center">
                                                        Day
                                                    </th>
                                                    <th width="15%" class="text-center">
                                                        Status
                                                    </th>
                                                    <th width="18%" class="text-center">
                                                        Action
                                                    </th>
                                                    <th width="30%" class="text-center">
                                                       Compound
                                                    </th>
                                                    <th width="20%" class="text-center">
                                                        Dosage
                                                    </th>
                                                    <th width="5%" class="text-center">
                                                        <i class="fas fa-exchange-alt"></i>
                                                    </th>
                                                </tr>
                                            </thead>
											<tbody v-for="schedule in schedules" :key="schedule.schedule_id">
												<tr v-if="schedule.action.action_type!='Labour'">
													<td>{{ schedule.day}}</td>
													<td>{{ schedule.stage.stage_name}}</td>
													<td>{{ schedule.action.action_name}}</td>
													<td>{{ schedule.description}}</td>
													<td>{{ schedule.dosage}}</td>
													<td class="text-nowrap text-center">
                                                        <button v-can="'schedules.update'" class="btn btn-outline-success btn-xs" type="button" @click="edit(schedule)">
                                                            <i class="far fa-edit"></i>
                                                        </button>

                                                        <button v-can="'schedules.delete'" class="btn btn-outline-danger btn-xs" type="button" @click="destory(schedule)">
                                                            <i class="far fa-trash-alt"></i>
                                                        </button>
                                                    </td>
												</tr>
                                            </tbody>
                                        </table>
										<table class="table table-responsive-sm table-bordered table-sm">
											<thead>
                                                <tr>
                                                    <th width="12%" class="text-center">
                                                        Day
                                                    </th>
                                                    <th width="15%" class="text-center">
                                                        Status
                                                    </th>
                                                    <th width="18%" class="text-center">
                                                        Action
                                                    </th>
                                                    <th width="50%" class="text-center">
                                                       Activity
                                                    </th>
                                                    <th width="5%" class="text-center">
                                                        <i class="fas fa-exchange-alt"></i>
                                                    </th>
                                                </tr>
                                            </thead>
											<tbody v-for="schedule in schedules" :key="schedule.schedule_id">
												<tr v-if="schedule.action.action_type=='Labour'">
													<td>{{ schedule.day}}</td>
													<td>{{ schedule.stage.stage_name}}</td>
													<td>{{ schedule.action.action_name}}</td>
													<td>{{ schedule.description}}</td>
													<td class="text-nowrap text-center">
                                                        <button v-can="'schedules.update'" class="btn btn-outline-success btn-xs" type="button" @click="edit(schedule)">
                                                            <i class="far fa-edit"></i>
                                                        </button>

                                                        <button v-can="'schedules.delete'" class="btn btn-outline-danger btn-xs" type="button" @click="destory(schedule)">
                                                            <i class="far fa-trash-alt"></i>
                                                        </button>
                                                    </td>
												</tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'Schedules.Create',
		data(){
            return{
                status:true,
				schedule:{
					schedule_id:'',
					user_id:'',
					farm_id:'',
					sub_farm_id:'',
					day:'',
					action_id:'',
					description:'',
					stage_id:''
				},
				schedules:[],
				particular:{
					particular_id:'',
					schedule_id:'',
					day:'',
					user_id:'',
					farm_id:'',
					status:'',
					action_id:'',
					action:{},
					compound:'',
					dosage:'',
					state:true,
					key:'',
				},
				farm_id:'',
				sub_farm_id:'',
				description:'Description',
				locations:[],
				stages:[],
				crops:[],
				actions:[],
				errors:[],
				isReadOnly:'',
				place_holder:''
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.farm_id = to.query.farm_id;
				vm.sub_farm_id = to.query.sub_farm_id;
				vm.getActions();
				vm.schedule.user_id = vm.$store.getters.user.user_id
				vm.schedule.farm_id = vm.farm_id
				vm.schedule.sub_farm_id = vm.$route.params.sub_farm_id
				vm.getStages()
				vm.getSchedules()
			});
        },

		watch:{
			'particular.action_id':function(){
				let vm = this;
				let action = vm.actions.filter(function (el) {
					return el.action_id == vm.particular.action_id 
				});
				vm.particular.action = action[0];
			}	
		},
		mounted(){
			this.getActions();
			this.schedule.user_id = this.$store.getters.user.user_id
			this.schedule.farm_id = this.farm_id
			this.schedule.sub_farm_id = this.$route.params.sub_farm_id
			this.getStages()
			this.getSchedules()
		},

		methods:{

			getDescription(event){
				let vm = this;
				vm.actions.filter(function (value) {
					if(value.action_id == event.target.value){
						vm.description = value.description
						vm.place_holder = value.description
						if(value.action_type == 'Compound'){
							vm.isReadOnly = false
						}else{
							vm.isReadOnly = true	
						}
					}
				})
			},

			getStages(){
				let vm = this
				let uri = {'uri':'stages','data':vm.schedule}
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.stages = response.data.data
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getActions() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'actions','data':vm.schedule};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.actions = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'schedules','data':vm.schedule};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Schedule is successfully created');
					vm.getSchedules()
					vm.discard()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
			getSchedules(){
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'schedules?sub_farm_id='+vm.sub_farm_id,'data':vm.schedule};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.schedules = response.data.data
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
			sortCompaniesByName(){
				this.schedules.sort( function( a, b ){
					return ( ( a.dosage == b.dosage ) ? 0 : ( ( a.dosage > b.dosage ) ? 1 : -1 ) );
				}.bind(this));
			},

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'schedules/'+vm.schedule.schedule_id,'data':vm.schedule};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Schedule is successfully updated');
					vm.getSchedules()
					vm.discard()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
			destory(schedule){
				if(confirm("Are you sure you want to delete?")){
					let vm = this;
					let loader = vm.$loading.show();
					let uri = {'uri':'schedules/'+schedule.schedule_id,'data':vm.schedule};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Schedule is successfully deleted');
						vm.getSchedules()
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},
			edit(schedule) {
				let vm = this;
				vm.schedule = schedule;
				vm.particular.state = false;
				vm.schedule.schedule_id = schedule.schedule_id;
				console.log(schedule)
				if(schedule.dosage == null){
					vm.isReadOnly = true
				}else{
					vm.isReadOnly = false
				}
			},
			discard() {
				let vm = this;
				vm.schedule.schedule_id = ''
				vm.schedule.day = ''
				vm.schedule.action_id = ''
				vm.schedule.description = ''
				vm.schedule.stage_id = ''
				vm.schedule.dosage = ''
				vm.schedule.$refs.day.focus()
			}
		}
	}
</script>
