<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fab fa-aws"></i>
							IOT Data
						</h3>
					</div>
				</div>
			</div>
		</section>
		<!-- Main content -->
		<div class="content">
			<div class="container">
				<div class="card">
					<div class="card-header">
						<div class="row">
							<div class="col-lg-3">
								<div class="form-group">
									<label class="form-label">Farm</label>
									<select class="form-control" v-model="filter.farm_id" ref="farm_id" @change="getIotNodes">
										<option value="">Select Farm</option>
										<option v-for="farm,key in farms" :key="key" :value="farm.farm_id">{{ farm.farm_name }}</option>
									</select>
								</div>
							</div>
							<div class="col-lg-3">
								<div class="form-group">
									<label class="form-label">IoT Node</label>
									<select class="form-control" v-model="filter.node_name" @change="getIoTRawData">
										<option value="">Select IoT Nodes</option>
										<option v-for="farm_node,key in farm_nodes" :key="key" :value="farm_node.node.node_name">{{ farm_node.node.node_name }}</option>
									</select>
								</div>
							</div>
							<div class="col-lg-3">
								<div class="form-group">
									<label class="form-label">From Date</label>
									<input type="date" class="form-control" v-model="filter.from_date" @change="getIoTRawData">
								</div>
							</div>
							<div class="col-lg-3">  
								<div class="form-group">
									<label class="form-label">To Date</label>  
									<input type="date" class="form-control" v-model="filter.to_date" @change="getIoTRawData">
								</div>
							</div>
							<div class="col-sm-12">
								<div class="card">
									<div class="card-body">
										<table class="table table-responsive-sm  table-hover table-sm">
											<thead>
												<tr>
													<th class="text-center">#</th>
													<th>Date</th>
													<th>Node Name</th>
													<th>Parameter</th>
													<th class="text-right">Value</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(node,key) in nodes" :key="key">
													<td class="text-center">
														{{ meta.from+key }}
													</td>
													<td>{{ $filters.formatDateTime(node.created_at) }}</td>
													
													<td>{{ node.node_name }}</td>
													<td>{{ node.field_name }}</td>
													<td class="text-right">{{ node.field_value }}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div class="card-footer clearfix">
										<select class="form-control form-control-sm per_page float-left" type="text" placeholder="Search" v-model="meta.per_page" @change="onPerPageChange">
											<option>5</option>
											<option>10</option>
											<option>15</option>
											<option>20</option>
											<option>25</option>
										</select>
										<Pagination
											:maxPage="meta.maxPage"
											:totalPages="meta.lastPage"
											:currentPage="meta.currentPage"
											@pagechanged="onPageChange"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination.vue';
	export default {
		name: 'Home',
		components: { Pagination },
		data(){
			return{
				filter:{
					farm_id:'',
					sub_farm_id:'',
					user_id:this.$store.state.user.user_id,
					role:this.$store.state.user.role,
					node_name:'',
					field_name:'',
					from_date:new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().slice(0,10),
					to_date:new Date().toISOString().slice(0,10),
				},

				meta: {
                    search:'',
					order_by:'desc',
					keyword:'created_at',
					per_page:20,
                    totalRows:0,
                    currentPage:1,
                    lastPage:1,
                    from: 1,
                    maxPage:1,
                },
				farms:[],
				sub_farms:[],
				farm_nodes:[],
				nodes:[],
				analytics:{},
				errors:[]
			}
		},

		beforeRouteEnter(to, from, next) {
			next(vm => {
                vm.filter.farm_id = to.query.farm_id;
				vm.filter.sub_farm_id = to.query.sub_farm_id;
				vm.getFarms();
			});
		},
		
		methods:{
			getFarms() {
				let vm = this;
                let uri = {'uri':'farms?user_id='+vm.filter.user_id+'&role='+vm.filter.role};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.farms = response.data.data;
					if(vm.farms.length!=0) {
						vm.getIotNodes()
					}
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getIotNodes() {
				let vm = this;
                let uri = {'uri':'farm_nodes?farm_id='+vm.filter.farm_id+'&sub_farm_id='+vm.filter.sub_farm_id};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.farm_nodes = response.data.data;
					if(vm.farm_nodes.length!=0) {
						vm.filter.node_name = vm.farm_nodes[0].node.node_name;
						vm.getIoTRawData();
					}
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getIoTRawData() {
				let vm = this;
                let uri = {'uri':'get_iot_raw_data?farm_id='+vm.filter.farm_id+'&sub_farm_id='+vm.filter.sub_farm_id+'&node_name='+vm.filter.node_name+'&from_date='+vm.filter.from_date+'&to_date='+vm.filter.to_date+'&page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&keyword='+vm.meta.keyword+'&per_page='+vm.meta.per_page};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.nodes = response.data.data;
					vm.meta.totalRows = response.data.total;
                    vm.meta.lastPage = response.data.last_page;
                    vm.meta.from = response.data.from;
					console.log(response.data)
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.getIoTRawData();
            },

			onPerPageChange() {
                this.meta.currentPage = 1;
                this.getIoTRawData();
            }
		}
	}
</script>
<style scoped>
	
</style>