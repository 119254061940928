<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-seedling"></i>
							{{ farm.farm_name }}
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link to="/" class="btn btn-secondary">
								<i class="fas fa-arrow-left"></i> Back
							</router-link>
							<router-link v-can="'farms.update'" :to="'/farms/'+farm.farm_id+'/edit?'" class="btn btn-success">
								<i class="fas fa-edit"></i> Edit
							</router-link>
							<button v-can="'farms.delete'" class="btn btn-danger" @click="destroy"> 
								<i class="far fa-trash-alt"></i> Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-4">
						<div class="card card-widget widget-user">
                            <div class="widget-user-header bg-secondary">
                                <h3 class="widget-user-username">
                                    {{ farm.farm_name }}
                                </h3>
                                <h5 class="widget-user-desc" v-if="farm.area">
                                    {{ farm.area }} Acre
                                </h5>
								<h5 class="widget-user-desc" v-else>
                                    {{ farm.farm_type }}
                                </h5>
                            </div>
                            <div class="widget-user-image">
                                <img class="img-circle elevation-2" src="@/assets/farm.jpg" alt="Image">
                            </div>
							<div class="card-body post" style="padding-top: 20px;">
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-bars"></i> 
									</div>
									<span class="username">
										{{ farm.farm_type }}
									</span>
									<span class="description">
										Farm Type
									</span>
								</div>
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-map-marker-alt"></i>
									</div>
									<span class="username">
										{{ farm.latitude }}
									</span>
									<span class="description">
										Latitude
									</span>
								</div>
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-map-marker-alt"></i>
									</div>
									<span class="username">
										{{ farm.longitude }}
									</span>
									<span class="description">
										Longitude
									</span>
								</div>
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-map-marker-alt"></i>
									</div>
									<span class="username">
										{{ farm.location }}
									</span>
									<span class="description">
										Address
									</span>
								</div>
							</div>
						</div>
						<div class="card" v-if="farm.status=='Closed'">
							<div class="card-body">
								<canvas ref="myChart" height="250"></canvas>
							</div>
						</div>
					</div>
					<div class="col-sm-8">
						<div class="row">
							<div class="col-lg-3 col-6" v-can="'expenses.view'">
								<div class="small-box bg-danger">
									<div class="inner">
										<h3>{{ total_expenses }}</h3>
										<p>Total Expenses</p>
									</div>
									<div class="icon">
										<i class="fas fa-coins"></i>
									</div>
									<router-link :to="'/equipments?farm_id='+farm.farm_id+'&sub_farm_id='" v-can="'expenses.create'" class="small-box-footer">
										New Expense <i class="fas fa-plus-circle"></i>
									</router-link>
								</div>
							</div>
							<div class="col-lg-3 col-6" v-can="'farms.view'">
								<div class="small-box bg-success">
									<div class="inner">
										<h3>{{ farm.revenue }}</h3>
										<p>Total Revenue</p>
									</div>
									<div class="icon">
										<i class="fas fa-coins"></i>
									</div>
									<router-link v-can="'farms.update'" :to="'/farms/'+farm.farm_id+'/edit?'" class="small-box-footer">
										Update Revenue <i class="fas fa-plus-circle"></i>
									</router-link>
								</div>
							</div>
							<div class="col-lg-3 col-6" v-can="'farms.view'">
								<div class="small-box bg-primary">
									<div class="inner">
										<h3>{{ farm.revenue - total_expenses }}</h3>
										<p>Total Profit</p>
									</div>
									<div class="icon">
										<i class="fas fa-coins"></i>
									</div>
									<router-link v-can="'farms.update'" :to="'/farms/'+farm.farm_id+'/edit?'" class="small-box-footer">
										Update Revenue <i class="fas fa-plus-circle"></i>
									</router-link>
								</div>
							</div>
							<div class="col-lg-3 col-6" v-can="'farms.view'">
								<div class="small-box bg-warning">
									<div class="inner">
										<h3>{{ farm.status }}</h3>
										<p>Farm Status</p>
									</div>
									<div class="icon">
										<i class="fas fa-box"></i>
									</div>
									<router-link v-can="'farms.update'" :to="'/farms/'+farm.farm_id+'/edit?'" class="small-box-footer">
										Update Status <i class="fas fa-plus-circle"></i>
									</router-link>
								</div>
							</div>
							<div class="col-lg-3 col-6" v-can="'farm_images.view'">
								<div class="small-box bg-info">
									<div class="inner">
										<h3>-</h3>
										<p>Farm Gallery</p>
									</div>
									<div class="icon">
										<i class="fas fa-images"></i>
									</div>
									<router-link :to="'/farms/'+farm.farm_id+'/images?farm_id='+farm.farm_id" v-can="'farm_images.view'" class="small-box-footer">
										View Gallery <i class="fas fa-bars"></i>
									</router-link>
								</div>
							</div>
							<div class="col-lg-3 col-6" v-can="'farm_users.view'">
								<div class="small-box bg-warning">
									<div class="inner">
										<h3>{{ total_users }}</h3>
										<p>Total Users</p>
									</div>
									<div class="icon">
										<i class="fas fa-user-plus"></i>
									</div>
									<a href="#" v-can="'farm_users.create'" @click.prevent="userStatus=true" class="small-box-footer">
										New User <i class="fas fa-plus-circle"></i>
									</a>
								</div>
							</div>
							<div class="col-lg-3 col-6" v-can="'farm_nodes.view'">
								<div class="small-box bg-danger">
									<div class="inner">
										<h3>{{ total_nodes }}</h3>
										<p>Total IOT Nodes</p>
									</div>
									<div class="icon">
										<i class="fab fa-aws"></i>
									</div>
									<router-link :to="'/farm_nodes?farm_id='+farm.farm_id+'&sub_farm_id='" class="small-box-footer">
										IOT Data <i class="fas fa-bars"></i>
									</router-link>
								</div>
							</div>
						</div>
						<div class="card" v-can="'sub_farms.view'">
							<div class="card-header">
								<div class="card-title">
									<i class="fas fa-seedling"></i>
									Sub Farms
								</div>
								<div class="float-right" v-can="'sub_farms.create'">
									<button @click="addSubFarm" class="btn btn-primary">
										<i class="fas fa-plus"></i>
									</button>
								</div>
							</div>
							<div class="card-body">
								<table class="table table-responsive-sm  table-hover table-sm">
									<thead>
										<tr>
											<th>#</th>
											<th>SubFarm</th>
											<th>Crop</th>
											<th>Crop Cycle</th>
											<th>Crop Family</th>
											<!-- <th>Area</th> -->
											<th class="text-right">Plants</th>
											<th class="text-right">Date of Sowing</th>
										</tr>
									</thead>
									<tbody>
										<tr class="cursor" v-for="(sub_farm,key) in sub_farms" :key="key" @click="showSubFarm(sub_farm)">
											<td class="text-center">{{ key+1 }}</td>
											<td>{{ sub_farm.sub_farm_name }}</td>
											<td>{{ sub_farm.crop.crop_name }}</td>
											<td>{{ sub_farm.crop.crop_cycle }}</td>
											<td>{{ sub_farm.crop.crop_family }}</td>
											<!-- <td>{{ sub_farm.area }} Acre</td> -->
											<td class="text-right">{{ sub_farm.no_of_plants }}</td>
											<td class="text-right">{{ $filters.formatDate(sub_farm.date_of_sowing) }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="card" v-can="'farm_users.view'">
							<div class="card-header clearfix">
								<div class="card-title float-left">
									<i class="far fa-user"></i>
									Farm Users
								</div>
								<div class="float-right" v-can="'farm_users.create'">
									<button class="btn btn-primary" @click="userStatus=true">
										<i class="fas fa-plus"></i>
									</button>
								</div>
							</div>
							<div class="card-body">
								<table class="table table-responsive-sm  table-hover table-sm">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th>Name</th>
											<th>Mobile No</th>
											<th>Role</th>
											<th class="text-right">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(farm_user,key) in farm.farm_users" :key="key">
											<td class="text-center">{{ key+1 }}</td>
											<td>{{ farm_user.user.name }}</td>
											<td>{{ farm_user.user.mobile_no }}</td>
											<td>{{ farm_user.user.role.role }}</td>
											<td class="text-right">
												<button v-can="'farm_users.delete'" class="btn btn-xs btn-outline-danger" @click="destroyFarmUser(farm_user,key)">
													<i class="far fa-trash-alt"></i>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="card" v-can="'farm_nodes.view'">
							<div class="card-header clearfix">
								<div class="card-title float-left">
									<i class="fas fa-microchip"></i>
									IoT Nodes
								</div>
								<div class="float-right" v-can="'farm_nodes.create'">
									<button class="btn btn-primary" @click="nodeStatus=true">
										<i class="fas fa-plus"></i>
									</button>
								</div>
							</div>
							<div class="card-body">
								<table class="table table-responsive-sm  table-hover table-sm">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th>Node Name</th>
											<th>Node Address</th>
											<th class="text-right">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(farm_node,key) in farm.farm_nodes" :key="key">
											<td class="text-center">{{ key+1 }}</td>
											<td>
												<router-link :to="'/farm_node_data?farm_id='+farm.farm_id+'&sub_farm_id='">
													{{ farm_node.node.display_name }}
												</router-link>
											</td>
											<td>
												<router-link :to="'/farm_node_data?farm_id='+farm.farm_id+'&sub_farm_id='">
													{{ farm_node.node.node_name }}
												</router-link>
											</td>
											<td class="text-right">
												<button v-can="'farm_nodes.delete'" class="btn btn-xs btn-outline-danger" @click="destroyFarmNode(farm_node,key)">
													<i class="far fa-trash-alt"></i>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div> 
			</div>
		</div>
		<UserModal
			:farm_id="farm.farm_id"
			:status="userStatus"
			:investor_details="'No'"
			@onSaveUser="onSaveUser"
			@onStatusChange="userStatus=false"
		/>

		<NodeModal
			:farm_id="farm.farm_id"
			:status="nodeStatus"
			@onSaveNode="onSaveNode"
			@onStatusChange="nodeStatus=false"
		/>
	</div>

</template>

<script>
	import UserModal from '@/components/UserModal.vue';
	import NodeModal from '@/components/NodeModal.vue';
	import Chart from 'chart.js';
	export default {
		name: 'farms.Show',
		components: { UserModal, NodeModal },
		data(){
			return{
				status:false,
				userStatus:false,
				nodeStatus:false,
				farm:{},
				sub_farms:[],
				total_sub_farms:0,
				total_expense:0,
				total_users:0,
				total_nodes:0,
				total_expenses:0,
				errors:[]
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				let loader = vm.$loading.show();
				let uri = {'uri':'farms/'+to.params.farm_id};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.farm = response.data.data;
					vm.getSubfarms();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			});
		},
		methods:{

			getSubfarms() {
				let vm = this;
				let uri = 'sub_farms?farm_id='+vm.farm.farm_id;
				vm.$store.dispatch('get',{'uri':uri})
				.then(function (response) {
					vm.sub_farms = response.data.data;
					vm.total_sub_farms = vm.sub_farms.length;
					vm.total_users = vm.farm.farm_users.length;
					vm.total_nodes = vm.farm.farm_nodes.length;
					vm.getExpenseCount()
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getExpenseCount() {
				let vm = this;
                let uri = {'uri':'get_farm_count?farm_id='+vm.farm.farm_id};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.total_expenses = response.data['expenses'];
					vm.createChart();
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			createChart() {
                let vm = this;
                if(vm.chart) {
                    vm.chart.destroy()
                }
				const profit = vm.farm.revenue - vm.total_expenses;
				if(profit>=0) {
					const data = {
						labels: [
							'Farmer',
							'Investor',
							'Company'
						],
						datasets: [{
							label: 'Profit Chart',
							data: [vm.farm.former_share, vm.farm.investor_share, vm.farm.company_share],
							backgroundColor: [
								'green',
								'orange',
								'blue'
							]
						}]
					};
					var pieOptions = {
						events: false,
						animation: {
							duration: 500,
							easing: "easeOutQuart",
							onComplete: function () {
								var ctx = this.chart.ctx;
								ctx.font = Chart.helpers.fontString('20', 'normal', Chart.defaults.global.defaultFontFamily);
								ctx.textAlign = 'center';
								ctx.textBaseline = 'bottom';

								this.data.datasets.forEach(function (dataset) {

									for (var i = 0; i < dataset.data.length; i++) {
									var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
										total = dataset._meta[Object.keys(dataset._meta)[0]].total,
										mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
										start_angle = model.startAngle,
										end_angle = model.endAngle,
										mid_angle = start_angle + (end_angle - start_angle)/2;

									var x = mid_radius * Math.cos(mid_angle);
									var y = mid_radius * Math.sin(mid_angle);

									ctx.fillStyle = '#fff';
									var percent = String(Math.round(dataset.data[i]/total*100)) + "%";
									// ctx.fillText(dataset.data[i], model.x + x, model.y + y);
									// Display percent in another line, line break doesn't work for fillText
									ctx.fillText(percent, model.x + x, model.y + y + 15);
									}
								});               
							}
						}
					};
					vm.chart = new Chart(this.$refs.myChart, {
						type: 'pie',
						data: data,
						options: pieOptions
					});
				}
            },

			destroy() {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'farms/'+vm.farm.farm_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Farm is successfully deleted');
						vm.$router.push('/farms');
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

			onSaveUser(user) {
				this.userStatus = false;
				this.farm.farm_users.push(user)
			},

			onSaveNode(node) {
				this.nodeStatus = false;
				this.farm.farm_nodes.push(node)
			},

			destroyFarmUser(farm_user,key) {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'farm_users/'+farm_user.farm_user_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','User is successfully deleted');
						vm.farm.farm_users.splice(key, 1);
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

			destroyFarmNode(farm_node,key) {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'farm_nodes/'+farm_node.farm_node_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Node is successfully deleted');
						vm.farm.farm_nodes.splice(key, 1);
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

			addSubFarm() {
				this.$router.push('/sub_farms/create?farm_id='+this.farm.farm_id)
			},

			showSubFarm(sub_farm){
				this.$router.push('/sub_farms/'+sub_farm.sub_farm_id+'?farm_id='+this.farm.farm_id+'&sub_farm_id='+sub_farm.sub_farm_id)
			}
		}
	}
</script>
