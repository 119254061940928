<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-lock"></i>
							Permissions
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<button v-can="'permissions.create'" @click="addRoles" class="btn btn-primary">
								<i class="fa fa-plus"></i>
								Add Permissions
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="card">
					<div class="card-header">
						<div class="card-title">
							<i class="fas fa-lock"></i>
							Permissions
						</div>
						<div class="card-tools">
							<select class="form-control form-control-sm" :class="{'is-invalid': errors.role_id}" v-model="permission.role_id" @change="getPermissions">
								<option value="">Select Role</option>
								<option v-for="role,key in roles" :key="key" :value="role.role_id">{{ role.role }}</option>
							</select>
							<span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
						</div>
					</div>
					<div class="card-body">
						<table class="table table-responsive-sm  table-hover table-sm">
							<thead>
								<tr>
									<th class="text-center">#</th>
									<th>Module</th>
									<th class="text-center">View</th>
									<th class="text-center">Create</th>
									<th class="text-center">Update</th>
									<th class="text-center">Delete</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="permission,key in permission.permissions" :key="key">
									<td class="text-center">{{ key+1 }}</td>
									<td>{{ permission.module }}</td>
									<td class="text-center" v-for="access,key in permission.access" :key="key">
										<input type="checkbox" v-model="access.access" @click="updatepPermission($event,permission.module,access.permission)">
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Profile',
		data(){
			return{
				status:true,
				permission:{
					role_id:'',
					permissions:[],
				},
				roles:[],
				errors:[]
			}
		},
		mounted() {
			let vm = this;
			vm.getRoles();
		},
		methods:{
			getRoles() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'roles','data':vm.permission};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.roles = response.data.data;
					if(vm.roles.length!=0) {
						vm.permission.role_id=vm.roles[0].role_id;
						vm.getPermissions();
					}
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getPermissions() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'permissions?role_id='+vm.permission.role_id};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.permission.permissions = response.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			updatepPermission(event, module, permission) {
				let vm = this;
				let data = {
					role_id:vm.permission.role_id,
					module:module,
					permission:permission,
					access:event.target.checked
				};
				let uri = {'uri':'permissions','data':data};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					console.log(response)
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			addRoles() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'add_permission_roles?role_id='+vm.permission.role_id};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.getPermissions();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			}
		}
	}
</script>
