<template>
    <div class="card card-widget">
        <div class="card-header">
            <div class="card-title">
                <i class="far fa-comments"></i>
                Comments
            </div>
        </div>
        <div class="card-body card-comments">
            <div class="card-comment" v-for="(comment,key) in comments" :key="key">
                <img class="img-circle img-sm" :src="$store.getters.assetUrl+'/users/'+comment.user.avatar" alt="User Image">
                <div class="comment-text">
                    <span class="username">
                        {{ comment.user.name}}
                        <span class="text-muted float-right">
                            {{ comment.created_at }}
                        </span>
                    </span>
                    {{ comment.comment }}
                </div>
                <div class="float-right" v-if="$store.getters.user.user_id==comment.user_id">
                    <button type="button" class="btn btn-outline-success btn-xs" @click="editComment(comment,key)">
                        <i class="far fa-edit"></i>
                    </button>
                    <button type="button" class=" btn btn-outline-danger btn-xs" @click="destroyComment(comment,key)">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </div>
            </div>
        </div>
    
        <div class="card-footer">
            <img class="img-fluid img-circle img-sm" :src="$store.getters.assetUrl+'/users/'+$store.getters.user.avatar" alt="Alt Text">
            <div class="img-push">
                <input type="text" class="form-control form-control-sm" :class="{'is-invalid':errors.comment}" placeholder="Press enter to post comment" ref="comment" v-model="comment" @keyup.enter="callComment()">
                <span v-if="errors.comment" class="invalid-feedback">{{ errors.comment[0] }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Modal',
        props: {
            user_id: {
                type: Number,
                required: true
            },
            module_id: {
                type: Number,
                required: true
            },
            module: {
                type: String,
                required: true
            },
            comments: {
                type: Array,
                required: true
            },
        },
        data(){
            return{
                comment_id:'',
                comment:'',
                key:'',
                status:true,
                errors:[]
            }
        },
        methods: {
            callComment() {
                let vm = this;
                if(vm.status) {
                    vm.saveComment();
                }
                else {
                    vm.updateComment();
                }
            },

            saveComment() {
                let vm = this;
				let loader = vm.$loading.show();
                let data = {
                    'user_id':vm.user_id,
                    'module_id':vm.module_id,
                    'module':vm.module,
                    'comment':vm.comment,
                }
				let uri = {'uri':'comments','data':data};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch('success','Comment is successfully created');
                    vm.comment = "";
                    vm.comments.push(response.data.data)
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
            },

            editComment(comment,key) {
                let vm = this;
                vm.comment_id = comment.comment_id;
                vm.comment = comment.comment;
                vm.status = false;
                vm.key = key;
                vm.$refs.comment.focus();
            },

            updateComment() {
                let vm = this;
				let loader = vm.$loading.show();
                let data = {
                    'user_id':vm.user_id,
                    'module_id':vm.module_id,
                    'module':vm.module,
                    'comment':vm.comment,
                }
				let uri = {'uri':'comments/'+vm.comment_id,'data':data};
				vm.$store.dispatch('patch',uri)
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch('success','Comment is successfully updated');
                    vm.comments.splice(vm.key,1,response.data.data)
                    vm.comment_id = "";
                    vm.comment = "";
                    vm.key = "";
                    vm.status = true;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
            },

            destroyComment(comment,key) {
                let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'comments/'+comment.comment_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Comment is successfully deleted');
                        vm.comments.splice(key, 1);
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
            }
        }
    }
</script>
<style scoped>
    .btn-xs {
        margin-left: 5px;
    }
</style>