<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-walking"></i>
							Stages
						</h3>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-4" v-can="'stages.create'">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
                               <div class="card-title">
									<i class="fas fa-walking"></i>
									<span v-if="status"> New Stage</span>
									<span v-else> Update Stage</span>
								</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="stage">Stage Name *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.stage_name}" type="text" v-model="stage.stage_name" placeholder="Stage Name" ref="stage_name">
                                            <span v-if="errors.stage_name" class="invalid-feedback">{{ errors.stage_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="description">Description</label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.description}" type="text" v-model="stage.description" placeholder="Description"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button  class="btn btn-outline-danger" type="button" @click="discard">
                                        <i class="fas fa-ban"></i> Discard
                                    </button>
                                    <button class="btn btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
					<div class="col-sm-8">
						<div class="card">
                            <div class="card-header">
								<div class="card-title">
									<i class="fas fa-walking"></i>
									Stages
								</div>
								<div class="card-tools">
									<input class="form-control form-control-sm search" type="text" placeholder="Search" v-model="meta.search" @keyup="index" ref="search">
								</div>
                            </div>
                            <div class="card-body">
                                <table class="table table-responsive-sm  table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Stage Name</th>
                                            <th>Description</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(stage,key) in stages" :key="key">
                                            <td class="text-center">{{ meta.from+key }}</td>
                                            <td>{{ stage.stage_name }}</td>
                                            <td>{{ stage.description }}</td>
                                            <td class="text-center">
												<button v-can="'stages.update'" class="btn btn-xs btn-outline-success" @click="edit(stage)">
                                                    <i class="far fa-edit"></i>
                                                </button>
                                                <button v-can="'stages.delete'" class="btn btn-xs btn-outline-danger" @click="destroy(stage)">
                                                    <i class="far fa-trash-alt"></i>
                                                </button>
											</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer clearfix">
								<select class="form-control form-control-sm per_page float-left" type="text" placeholder="Search" v-model="meta.per_page" @change="onPerPageChange">
									<option>5</option>
									<option>10</option>
									<option>15</option>
									<option>20</option>
									<option>25</option>
								</select>
								<Pagination
									:maxPage="meta.maxPage"
									:totalPages="meta.lastPage"
									:currentPage="meta.currentPage"
									@pagechanged="onPageChange"
								/>
							</div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination.vue';
	export default {
		name: 'Stages',
		components: { Pagination },
		data(){
            return{
                status:true,
                stage:{
                    stage_id:'',
                    stage_name:'',
                    description:'',
                },
				meta: {
                    search:'',
					order_by:'asc',
					keyword:'stage_name',
					per_page:5,
                    totalRows:0,
                    currentPage:1,
                    lastPage:1,
                    from: 1,
                    maxPage:1,
                },
				stages:[],
                errors:[]
            }
        },
		mounted() {
            let vm = this;
            vm.discard();
        },
		methods:{
			discard(){
                let vm = this;
                vm.errors=[],
                vm.stage.stage_id='',
                vm.stage.stage_name='',
                vm.stage.description='',
                vm.status=true,
                vm.$refs.stage_name.focus(),
                vm.index()
            },

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			index() {
				let vm = this;
				let uri = 'stages?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&keyword='+vm.meta.keyword+'&per_page='+vm.meta.per_page;
				vm.$store.dispatch('get',{'uri':uri})
				.then(function (response) {
					vm.stages = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('post',{'uri':'stages','data':vm.stage})
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Stage is successfully created');
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(stage){
                let vm = this;
                vm.stage = stage,
                vm.status = false,
                vm.$refs.stage_name.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'stages/'+vm.stage.stage_id,'data':vm.stage};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Stage is successfully updated');
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			destroy(stage) {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'stages/'+stage.stage_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Stage is successfully deleted');
						vm.discard();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

			onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            }
		}
	}
</script>
