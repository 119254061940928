<template>
	<div class="auth">
		<div class="col-xl-8 col-11">
			<div class="card auth-bg">
				<div class="card-header" style="background:#000">
					<center><img src="../../assets/foster_innovation_logo_main.png" width="150" alt="Logo"></center>
				</div>
				<div class="row">
					<div class="col-md-6 col-12">
						<div class="card h-100 d-flex justify-content-center">
							<div class="card-body">
								<div class="heading">
									<div class="heading-text">
										Login to K2Farms
									</div>
								</div>
								<form @submit.prevent="login">
									<div class="form-group">
										<label class="form-label">Email Address</label>
										<input type="text" class="form-control" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="user.email" ref="email">
										<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
									</div>

									<div class="form-group">
										<label class="form-label">Password</label>
										<input type="password" class="form-control" :class="{'is-invalid': errors.password}" placeholder="Password" v-model="user.password">
										<span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
									</div>

									<div class="form-group d-flex flex-md-row flex-column justify-content-between align-items-center">
										<div class="text-left">
											<div class="checkbox checkbox-sm">
												<input type="checkbox" class="form-check-input" id="keep_me_logged_in">
												<label class="checkboxsmall" for="keep_me_logged_in">
													<small>Keep me logged in</small>
												</label>
											</div>
										</div>
										<div class="text-right">
											<router-link to="/forgot">
												<small>Forgot Password?</small>
											</router-link>
										</div>
									</div>

									<button type="submit" class="btn btn-primary w-100 position-relative" :disabled="$store.getters.loading">
										Login
										<i class="fa fa-arrow-right"></i>
									</button>
								</form>
							</div>
						</div> 
					</div>
					<div class="col-md-6 d-md-block d-none">
						<div class="card-body">
							<img class="img-fluid" src="@/assets/branding.png" alt="Branding">
						</div>
					</div>
				</div>
				<div class="card-footer" style="background:#000">
					<center><img src="../../assets/footer_logos.png" width="340" height="42" alt="Footer logo"></center>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Login',
		data(){
			return{
				user:{
					email:'',
					password:''
				},
				users:{},
				errors:[]
			}
		},
		mounted(){
			this.$refs.email.focus();
		},
		methods:{
            login() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('auth',{'uri':'login','data':vm.user})
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch('success','Successfuly logged in');
					vm.$store.dispatch('setUser',response.data);
					vm.getPermissions();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
            },

			getPermissions() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'permissions?user_id='+vm.$store.getters.user.user_id};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch('setPermissions',response.data.data);
					vm.$router.push('/');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			}
		}
	}
</script>

<style scoped>
	.card, .card-header, .card-body, .card-footer{
		border-radius: 0px;
	}
	.card-body{
		padding: 20px 50px 20px 50px;
	}
</style>