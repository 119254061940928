<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-7">
						<h1 v-if="sub_farm.farm">
							<i class="fas fa-seedling"></i>
							{{ sub_farm.farm.farm_name }}
                            | <small>{{ sub_farm.crop.crop_name }}</small>
						</h1>
					</div>
					<div class="col-sm-5">
						<div class="float-sm-right">
							<router-link :to="'/farms/'+farm_id" class="btn btn-secondary">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
							<router-link v-can="'sub_farms.update'" :to="'/sub_farms/'+sub_farm.sub_farm_id+'/edit?farm_id='+farm_id" class="btn btn-success">
								<i class="fas fa-edit"></i> Edit
							</router-link>
							<button v-can="'farm_users.view'" class="btn btn-primary" @click="usersStatus=true"> 
								<i class="fas fa-user"></i> Investors
							</button>
							<button v-can="'sub_farms.delete'" class="btn btn-danger" @click="destroy"> 
								<i class="far fa-trash-alt"></i> Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
        <div class="content" v-if="sub_farm.farm">
			<div class="container">
				<div class="row">
					<div class="col-sm-4">
						<div class="card card-widget widget-user">
                            <div class="widget-user-header bg-secondary">
                                <h3 class="widget-user-username">
                                    {{ sub_farm.sub_farm_name }}
                                </h3>
                                <h5 class="widget-user-desc">
                                    {{ sub_farm.crop.crop_name }}
                                </h5>
                            </div>
                            <div class="widget-user-image">
                                <img class="img-circle elevation-2" :src="$store.getters.assetUrl+'crops/'+sub_farm.crop.crop_image" alt="Image">
                            </div>
							<div class="card-body post" style="padding-top: 20px;">
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-seedling"></i> 
									</div>
									<span class="username">
										{{ sub_farm.crop.crop_name }}
									</span>
									<span class="description">
										Crop Name
									</span>
								</div>
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-square"></i> 
									</div>
									<span class="username">
										{{ sub_farm.crop.crop_cycle }}
									</span>
									<span class="description">
										Crop Cycle
									</span>
								</div>
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-seedling"></i> 
									</div>
									<span class="username">
										{{ sub_farm.crop.crop_family }}
									</span>
									<span class="description">
										Crop Family
									</span>
								</div>
                                <div class="user-block" v-if="sub_farm.area">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-square"></i> 
									</div>
									<span class="username">
										{{ sub_farm.area }} Acre
									</span>
									<span class="description">
										Area
									</span>
								</div>
                                <div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-bars"></i> 
									</div>
									<span class="username">
										{{ sub_farm.cultivation_type }}
									</span>
									<span class="description">
										Cultivation Type
									</span>
								</div>
                                <div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-dot-circle"></i> 
									</div>
									<span class="username">
										{{ sub_farm.no_of_plants }}
									</span>
									<span class="description">
										No of Plants
									</span>
								</div>
                                <div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-calendar-alt"></i> 
									</div>
									<span class="username">
										{{ $filters.formatDate(sub_farm.date_of_sowing) }}
									</span>
									<span class="description">
										Date of Sowing
									</span>
								</div>
                                <div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-building"></i> 
									</div>
									<span class="username">
										{{ sub_farm.seed_company_name }}
									</span>
									<span class="description">
										Seed Company Name
									</span>
								</div>
                                <div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-bars"></i> 
									</div>
									<span class="username">
										{{ sub_farm.seed_variety }}
									</span>
									<span class="description">
										Seed Variety
									</span>
								</div>
                                <div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-balance-scale"></i> 
									</div>
									<span class="username">
										{{ sub_farm.expected_tonnage }}
									</span>
									<span class="description">
										Expected Tonnage
									</span>
								</div>
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-list"></i> 
									</div>
									<span class="username">
										{{ sub_farm.nursing_source }}
									</span>
									<span class="description">
										Nursing Source
									</span>
								</div>
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-list"></i> 
									</div>
									<span class="username">
										{{ sub_farm.harvest_from }}
									</span>
									<span class="description">
										Harvest From 
									</span>
								</div>
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-list"></i> 
									</div>
									<span class="username">
										{{ $filters.formatDate(sub_farm.transplantation_date) }}
									</span>
									<span class="description">
										Transplantation Date
									</span>
								</div>
								<div class="user-block" v-if="sub_farm.completion_date">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-list"></i> 
									</div>
									<span class="username">
										{{ $filters.formatDate(sub_farm.completion_date) }}
									</span>
									<span class="description">
										Completion Date
									</span>
								</div>
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-list"></i> 
									</div>
									<span class="username">
										{{ sub_farm.water_source }}
									</span>
									<span class="description">
										Water Source
									</span>
								</div>
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-list"></i> 
									</div>
									<span class="username">
										{{ sub_farm.row_orientation }}
									</span>
									<span class="description">
										Row Orientation
									</span>
								</div>
								<div class="user-block">
									<div class="icon icon-circle icon-bordered-sm">
										<i class="fas fa-list"></i> 
									</div>
									<span class="username">
										{{ sub_farm.infrastructure }}
									</span>
									<span class="description">
										Infrastructure of Plant
									</span>
								</div>
							</div>
						</div>
					</div>
                    <div class="col-sm-8">
                        <div class="row">
							<div class="col-lg-3 col-6" v-can="'schedules.view'">
								<div class="small-box bg-info">
									<div class="inner">
										<h3>{{ total_schedules }}</h3>
										<p>Total Entry</p>
									</div>
									<div class="icon">
										<i class="fas fa-calendar-alt"></i>
									</div>
									<router-link :to="'/sub_farms/'+sub_farm_id+'/schedule?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" v-can="'schedules.create'" class="small-box-footer">
										New Schedule <i class="fas fa-plus-circle"></i>
									</router-link>
								</div>
							</div>
							<div class="col-lg-3 col-6" v-can="'logs.view'">
								<div class="small-box bg-primary">
									<div class="inner">
										<h3>{{ total_logs }}</h3>
										<p>Total Entry</p>
									</div>
									<div class="icon">
										<i class="fas fa-book"></i>
									</div>
									<router-link :to="'/sub_farms/'+sub_farm_id+'/log?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" v-can="'logs.create'" class="small-box-footer">
										New Farm Log <i class="fas fa-plus-circle"></i>
									</router-link>
								</div>
							</div>
							<div class="col-lg-3 col-6">
								<div class="small-box bg-danger">
									<div class="inner">
										<h3>{{ days }}</h3>
										<p>Days - Transplantation</p>
									</div>
									<div class="icon">
										<i class="fas fa-coins"></i>
									</div>
									<router-link v-can="'sub_farms.update'" :to="'/sub_farms/'+sub_farm.sub_farm_id+'/edit?farm_id='+farm_id" class="small-box-footer">
										Update Date <i class="fas fa-plus-circle"></i>
									</router-link>
								</div>
							</div>
							<div class="col-lg-3 col-6">
								<div class="small-box bg-success">
									<div class="inner">
										<h3>-</h3>
										<p>Total Consumption</p>
									</div>
									<div class="icon">
										<i class="fas fa-coins"></i>
									</div>
									<router-link to="#" class="small-box-footer">
										New Consumption <i class="fas fa-plus-circle"></i>
									</router-link>
								</div>
							</div>
							<div class="col-lg-3 col-6" v-can="'farm_images.view'">
								<div class="small-box bg-warning">
									<div class="inner">
										<h3>-</h3>
										<p>Farm Gallery</p>
									</div>
									<div class="icon">
										<i class="fas fa-images"></i>
									</div>
									<router-link v-can="'farm_images.view'" :to="'/sub_farms/'+sub_farm.sub_farm_id+'/images?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="small-box-footer">
										Upload Image <i class="fas fa-upload"></i>
									</router-link>
								</div>
							</div>
							<div class="col-lg-3 col-6" v-can="'farm_nodes.view'">
								<div class="small-box bg-danger">
									<div class="inner">
										<h3>{{ total_farm_nodes }}</h3>
										<p>Total IOT Nodes</p>
									</div>
									<div class="icon">
										<i class="fab fa-aws"></i>
									</div>
									<router-link :to="'/farm_nodes?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="small-box-footer">
										IOT Data <i class="fas fa-bars"></i>
									</router-link>
								</div>
							</div>
                            <div class="col-lg-3 col-6" v-can="'pests.view'">
								<div class="small-box bg-primary">
									<div class="inner">
										<h3>{{ total_pests }}</h3>
										<p>Total Pest Records</p>
									</div>
									<div class="icon">
										<i class="fas fa-book"></i>
									</div>
                                    <router-link v-can="'pests.view'" :to="'/pests?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="small-box-footer">
                                        New Record <i class="fas fa-plus-circle"></i>
                                    </router-link>
								</div>
							</div>
                            <div class="col-lg-3 col-6" v-can="'diseases.view'">
								<div class="small-box bg-warning">
									<div class="inner">
										<h3>{{ total_diseases }}</h3>
										<p>Total Disease Records</p>
									</div>
									<div class="icon">
										<i class="fas fa-pastafarianism"></i>
									</div>
                                    <router-link v-can="'diseases.view'" :to="'/diseases?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="small-box-footer">
                                        New Record <i class="fas fa-plus-circle"></i>
                                    </router-link>
								</div>
							</div>
							<div class="col-lg-3 col-6" v-can="'sprays.view'">
								<div class="small-box bg-success">
									<div class="inner">
										<h3>{{ total_sprays }}</h3>
										<p>Total Spray Records</p>
									</div>
									<div class="icon">
										<i class="fas fa-spray-can"></i>
									</div>
                                    <router-link v-can="'sprays.view'" :to="'/sprays?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="small-box-footer">
                                        New Record <i class="fas fa-plus-circle"></i>
                                    </router-link>
								</div>
							</div>
                            <div class="col-lg-3 col-6" v-can="'harvests.view'">
								<div class="small-box bg-info">
									<div class="inner">
										<h3>{{ total_harvests }}</h3>
										<p>Total Harvest</p>
									</div>
									<div class="icon">
										<i class="fas fa-cut"></i>
									</div>
                                    <router-link v-can="'harvests.view'" :to="'/harvests?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="small-box-footer">
                                        New Record <i class="fas fa-plus-circle"></i>
                                    </router-link>
								</div>
							</div>
						</div>
                        <div class="card" v-can="'schedules.view'">
                            <div class="card-header">
                                <div class="card-title">
                                    <i class="fas fa-calendar-alt"></i>
                                    Schedule
                                </div>
                                <div class="card-tools">
                                    <input type="date" v-model="date" class="form-control form-control-sm" @change="getSchedules">
                                </div>
                            </div>
                            <div class="card-body">
                                <table class="table table-responsive-sm  table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Day</th>
                                            <th>Stage</th>
                                            <th>Action</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(schedule,key) in schedules" :key="key">
                                            <td class="text-center">{{key+1 }}</td>
                                            <td>Day {{ schedule.day }}</td>
                                            <td>{{ schedule.stage.stage_name }}</td>
                                            <td>{{ schedule.action.action_name }}</td>
                                            <td>{{ schedule.description }} - {{ schedule.dosage }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
						<div class="card" v-can="'logs.view'">
                            <div class="card-header">
                                <div class="card-title">
                                    <i class="fas fa-book"></i>
                                    Farm Logs
                                </div>
                            </div>
                            <div class="card-body">
                                <table class="table table-responsive-sm  table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Date</th>
                                            <th>Activity</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(log,key) in logs" :key="key">
                                            <td class="text-center">{{key+1 }}</td>
                                            <td>{{ $filters.formatDate(log.date) }}</td>
                                            <td>{{ log.activity }}</td>
                                            <td>{{ log.description }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
						<div class="card" v-can="'farm_users.view'">
							<div class="card-header clearfix">
								<div class="card-title float-left">
									<i class="far fa-user"></i>
									Farm Users
								</div>
								<div class="float-right" v-can="'farm_users.create'">
									<button class="btn btn-primary" @click="userStatus=true">
										<i class="fas fa-plus"></i>
									</button>
								</div>
							</div>
							<div class="card-body">
								<table class="table table-responsive-sm  table-hover table-sm">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th>Name</th>
											<th>Mobile No</th>
											<th>Role</th>
											<th class="text-right">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(farm_user,key) in sub_farm.farm_users" :key="key">
											<td class="text-center">{{ key+1 }}</td>
											<td>{{ farm_user.user.name }}</td>
											<td>{{ farm_user.user.mobile_no }}</td>
											<td>{{ farm_user.user.role.role }}</td>
											<td class="text-right">
												<button v-can="'farm_users.delete'" class="btn btn-xs btn-outline-danger" @click="destroyFarmUser(farm_user,key)">
													<i class="far fa-trash-alt"></i>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
                    </div>
				</div> 
			</div>
		</div>
		<UserModal
			:farm_id="farm_id"
			:sub_farm_id="sub_farm_id"
			:investor_details="'Yes'"
			:status="userStatus"
			@onSaveUser="onSaveUser"
			@onStatusChange="userStatus=false"
		/>
		<InvestorModal
			:status="usersStatus"
			:farm_investors="sub_farm.farm_investors"
			@onStatusChange="usersStatus=false"
		/>
	</div>

</template>
<script>
	import UserModal from '@/components/UserModal.vue';
	import InvestorModal from '@/components/InvestorModal.vue';
    export default {
		name: 'sub_farms.Show',
		components: { UserModal, InvestorModal },
		data(){
			return{
                farm_id:'',
				sub_farm_id:'',
                date:new Date().toISOString().slice(0,10),
				sub_farm:{},
                schedules:[],
				logs:[],
				errors:[],
				userStatus:false,
				usersStatus:false,

				total_schedules:0,
				total_logs:0,
				days:0,
				total_expenses:0,
				total_farm_users:0,
				total_farm_nodes:0,
				total_pests:0,
				total_diseases:0,
				total_sprays:0,
				total_harvests:0,
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
                vm.farm_id = to.query.farm_id;
				vm.sub_farm_id = to.query.sub_farm_id;
				let loader = vm.$loading.show();
				let uri = {'uri':'sub_farms/'+to.params.sub_farm_id};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.sub_farm = response.data.data
                    vm.getSchedules()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			});
		},
		methods:{
            getSchedules() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'get_schedules','data':{'farm_id':vm.farm_id,'sub_farm_id':vm.sub_farm_id,'date':vm.date}};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					loader.hide();
					vm.schedules = response.data.data;
					vm.getLogs();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getLogs() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'get_logs','data':{'farm_id':vm.farm_id,'sub_farm_id':vm.sub_farm_id}};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					loader.hide();
					vm.logs = response.data.data;
					vm.getCount();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getCount() {
				let vm = this;
                let uri = {'uri':'get_sub_farm_count?sub_farm_id='+vm.sub_farm_id};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.total_schedules = response.data['schedules'];
					vm.total_logs = response.data['logs'];
					vm.days = response.data['days'];
					vm.total_expenses = response.data['expenses'];
					vm.total_farm_users = response.data['farm_users'];
					vm.total_farm_nodes = response.data['farm_nodes'];
					vm.total_pests = response.data['pests'];
					vm.total_diseases = response.data['diseases'];
					vm.total_sprays = response.data['sprays'];
					vm.total_harvests = response.data['harvests'];
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

            destroy() {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'sub_farms/'+vm.sub_farm.sub_farm_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','SubFarm is successfully deleted');
						vm.$router.push('/farms/'+vm.farm_id);
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

			destroyFarmUser(farm_user,key) {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'farm_users/'+farm_user.farm_user_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','User is successfully deleted');
						vm.sub_farm.farm_users.splice(key, 1);
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

			onSaveUser(user) {
				this.userStatus = false;
				this.sub_farm.farm_users.push(user)
			},
		}
	}
</script>