<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-user-tag"></i>
							Roles
						</h3>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-4" v-can="'roles.create'">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
								<div class="card-title">
									<i class="fas fa-user-tag"></i>
									<span v-if="status"> New Role</span>
									<span v-else> Update Role</span>
								</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="role">Role *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.role}" type="text" v-model="role.role" placeholder="Role" ref="role">
                                            <span v-if="errors.role" class="invalid-feedback">{{ errors.role[0] }}</span>
                                        </div>
                                    </div>
									<div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="priority">Priority *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.priority}" type="number" v-model="role.priority" placeholder="Priority">
                                            <span v-if="errors.priority" class="invalid-feedback">{{ errors.priority[0] }}</span>
                                        </div>
                                    </div>
									<div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="is_investor">Is Investor *</label>
                                            <select class="form-control form-control-sm" :class="{'is-invalid': errors.is_investor}"  v-model="role.is_investor">
												<option value="1">Yes</option>
												<option value="0">No</option>
											</select>
                                            <span v-if="errors.is_investor" class="invalid-feedback">{{ errors.is_investor[0] }}</span>
                                        </div>
                                    </div>
									<div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="description">Description</label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.description}" type="text" v-model="role.description" placeholder="Description"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button  class="btn btn-outline-danger" type="button" @click="discard">
                                        <i class="fas fa-ban"></i> Discard
                                    </button>
                                    <button class="btn btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
					<div class="col-sm-8">
						<div class="card">
                            <div class="card-header">
								<div class="card-title">
									<i class="fas fa-user-tag"></i>
									Roles
								</div>
								<div class="card-tools">
									<input class="form-control form-control-sm search" type="text" placeholder="Search" v-model="meta.search" @keyup="index" ref="search">
								</div>
                            </div>
                            <div class="card-body">
                                <table class="table table-responsive-sm  table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Role</th>
											<th>Priority</th>
											<th>isInvestor</th>
											<th>Description</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(role,key) in roles" :key="key">
                                            <td class="text-center">{{ meta.from+key }}</td>
                                            <td>{{ role.role }}</td>
											<td>{{ role.priority }}</td>
											<td>{{ role.is_investor==0?'No':'Yes' }}</td>
											<td>{{ role.description }}</td>
                                            <td class="text-center">
												<button v-can="'roles.update'" class="btn btn-xs btn-outline-success" @click="edit(role)">
                                                    <i class="far fa-edit"></i>
                                                </button>
                                                <button v-can="'roles.delete'" class="btn btn-xs btn-outline-danger" @click="destroy(role)">
                                                    <i class="far fa-trash-alt"></i>
                                                </button>
											</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer clearfix">
								<select class="form-control form-control-sm per_page float-left" type="text" placeholder="Search" v-model="meta.per_page" @change="onPerPageChange">
									<option>6</option>
									<option>10</option>
									<option>15</option>
									<option>20</option>
									<option>25</option>
								</select>
								<Pagination
									:maxPage="meta.maxPage"
									:totalPages="meta.lastPage"
									:currentPage="meta.currentPage"
									@pagechanged="onPageChange"
								/>
							</div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination.vue';
	export default {
		name: 'Home',
		components: { Pagination },
		data(){
            return{
                status:true,
                role:{
                    role_id:'',
                    role:'',
					priority:'',
					is_investor:0,
					description:'',
                },
				meta: {
                    search:'',
					order_by:'asc',
					keyword:'priority',
					per_page:6,
                    totalRows:0,
                    currentPage:1,
                    lastPage:1,
                    from: 1,
                    maxPage:1,
                },
				roles:[],
                errors:[]
            }
        },
		mounted() {
            let vm = this;
            vm.discard();
        },
		methods:{
			discard(){
                let vm = this;
                vm.errors=[],
                vm.role.role_id='',
                vm.role.role='',
				vm.role.priority='',
				vm.role.is_investor=0,
				vm.role.description='',
                vm.status=true,
                vm.$refs.role.focus(),
                vm.index()
            },

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			index() {
				let vm = this;
				let uri = 'roles?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&keyword='+vm.meta.keyword+'&per_page='+vm.meta.per_page;
				vm.$store.dispatch('get',{'uri':uri})
				.then(function (response) {
					vm.roles = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('post',{'uri':'roles','data':vm.role})
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Role is successfully created');
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(role){
                let vm = this;
                vm.role = role,
                vm.status = false,
                vm.$refs.role.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'roles/'+vm.role.role_id,'data':vm.role};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Role is successfully updated');
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			destroy(role) {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'roles/'+role.role_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Role is successfully deleted');
						vm.discard();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

			onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            }
		}
	}
</script>
