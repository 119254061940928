<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-8">
						<h1 v-if="farm">
							<i class="fas fa-seedling"></i>
							{{ farm.farm_name }}
						</h1>
					</div>
					<div class="col-sm-4">
						<div class="float-sm-right">
							<router-link :to="'/farms/'+farm.farm_id+'?farm_id='+farm_id" class="btn btn-secondary">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
        <div class="content" v-if="farm">
			<div class="container">
				<div class="row">
					<div class="col-sm-12" v-can="'farm_images.view'">
                        <div class="card">
							<div class="card-header clearfix">
								<div class="card-title">
									<i class="fas fa-image"></i>
									Images 
								</div>
							</div>
							<div class="card-body row">
								<div class="col-sm-2" v-for="file,key in files" :key="key">
									<div class="card text-center">
										<a :href="$store.getters.assetUrl+'files/'+file.file" data-lightbox="example-2"><img class="img img-thumbnail" :src="$store.getters.assetUrl+'files/'+file.file"></a>
									</div>
								</div>
							</div>
                            <div class="card-footer clearfix">
                                <select class="form-control form-control-sm per_page float-left" type="text" placeholder="Search" v-model="meta.per_page" @change="onPerPageChange">
                                    <option>6</option>
                                    <option>12</option>
                                    <option>18</option>
                                    <option>24</option>
                                    <option>32</option>
                                </select>
                                <Pagination
                                    :maxPage="meta.maxPage"
                                    :totalPages="meta.lastPage"
                                    :currentPage="meta.currentPage"
                                    @pagechanged="onPageChange"
                                />
                            </div>
						</div>
                    </div>
				</div> 
			</div>
		</div>
	</div>

</template>
<script>
    import Pagination from '@/components/Pagination.vue';
    export default {
		name: 'farms.images',
        components: { Pagination },
		data(){
			return{
                farm_id:'',
				farm:{},
                files:[],
                meta: {
                    search:'',
					order_by:'asc',
					keyword:'created_at',
					per_page:18,
                    totalRows:0,
                    currentPage:1,
                    lastPage:1,
                    from: 1,
                    maxPage:1,
                },
                status:false,
                images:[],
				errors:[]
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
                vm.farm_id = to.query.farm_id;
				let loader = vm.$loading.show();
				let uri = {'uri':'farms/'+to.params.farm_id};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.farm = response.data.data;
                    vm.getImages()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			});
		},
		methods:{

            getImages() {
				let vm = this;
				let uri = 'files?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&keyword='+vm.meta.keyword+'&per_page='+vm.meta.per_page+'&farm_id='+vm.farm_id+'&sub_farm_id=';
				vm.$store.dispatch('get',{'uri':uri})
				.then(function (response) {
					vm.files = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

            onPageChange(page) {
                this.meta.currentPage = page;
                this.getImages();
            },

			onPerPageChange() {
                this.meta.currentPage = 1;
                this.getImages();
            }
		}
	}
</script>