<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-cut"></i>
								Harvest Records
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link :to="'/harvests?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-secondary">
								<i class="fas fa-arrow-left"></i>
								Back
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
                                <strong v-if="status">New Harvest</strong>
                                <strong v-else>Update Harvest</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Farm *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.farm_id}" v-model="harvest.farm_id" ref="farm_id" @change="getSubFarms">
												<option value="">Select Farm</option>
												<option v-for="farm,key in farms" :key="key" :value="farm.farm_id">{{ farm.farm_name }}</option>
											</select>
											<span v-if="errors.farm_id" class="invalid-feedback">{{ errors.farm_id[0] }}</span>
										</div>
									</div>
                                    <div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Sub Farm *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.sub_farm_id}" v-model="harvest.sub_farm_id">
												<option value="">Select Sub Farm</option>
												<option v-for="sub_farm,key in sub_farms" :key="key" :value="sub_farm.sub_farm_id">{{ sub_farm.crop.crop_name }}</option>
											</select>
											<span v-if="errors.sub_farm_id" class="invalid-feedback">{{ errors.sub_farm_id[0] }}</span>
										</div>
									</div>
                                    <div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Weight *</label>
											<input type="text" placeholder="Weight" class="form-control form-control-sm" :class="{'is-invalid': errors.weight}" v-model="harvest.weight">
											<span v-if="errors.weight" class="invalid-feedback">{{ errors.weight[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Date *</label>
											<input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.date}" v-model="harvest.date">
											<span v-if="errors.date" class="invalid-feedback">{{ errors.date[0] }}</span>
										</div>
									</div>
                                    <div class="col-sm-12">
										<div class="form-group">
											<label class="form-label">Note</label>
											<textarea class="form-control form-control-sm" :class="{'is-invalid': errors.note}" placeholder="Note" v-model="harvest.note"></textarea>
											<span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
										</div>
									</div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link :to="'/harvests?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-outline-danger" type="button">
                                        <i class="fas fa-ban"></i> Discard
                                    </router-link>
                                    <button class="btn btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'Harvests.Create',
		data(){
            return{
                status:true,
                harvest:{
                    harvest_id:'',
                    sub_farm_id:'',
					farm_id:'',
					date:new Date().toISOString().slice(0,10),
					weight:'',
                    note:'',
                    user:'',
                    user_id:''
				},
				farm_id:'',
				sub_farm_id:'',
				user_id:this.$store.state.user.user_id,
				role:this.$store.state.user.role,
				farms:[],
                sub_farms:[],
				crops:[],
				errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.farm_id = to.query.farm_id;
				vm.sub_farm_id = to.query.sub_farm_id;
				vm.harvest.farm_id = vm.farm_id;
				vm.harvest.sub_farm_id = vm.sub_farm_id;
				if(to.name=='Harvests.Create') {
					vm.getFarms();
                    vm.harvest.user_id = vm.$store.getters.user.user_id;
					vm.$refs.farm_id.focus();
				}
				else {
					let uri = {'uri':'harvests/'+to.params.harvest_id};
					vm.$store.dispatch('get',uri)
					.then(function (response) {
						vm.getFarms();
						vm.harvest = response.data.data;
						vm.status = false;
						vm.$refs.name.focus()
					})
					.catch(function (error) {
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			});
        },
		methods:{
			getFarms() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'farms?user_id='+vm.user_id+'&role='+vm.role};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.farms = response.data.data;
					vm.getSubFarms();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
            getSubFarms() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'sub_farms?farm_id='+vm.harvest.farm_id,'data':vm.harvest};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					console.log(response)
					vm.sub_farms = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'harvests','data':vm.harvest};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Harvest is successfully created');
					vm.$router.push('/harvests/?farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(sub_farm){
                let vm = this;
                vm.sub_farm = sub_farm,
                vm.status = false,
                vm.$refs.sub_farm.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'harvests/'+vm.harvest.harvest_id,'data':vm.harvest};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Harvest is successfully updated');
					vm.$router.push('/harvests/?farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            }
		}
	}
</script>
