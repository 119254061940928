<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-microchip"></i>
							IOT Nodes
						</h3>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-4" v-can="'nodes.create'">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
                                <div class="card-title">
									<i class="fas fa-microchip"></i>
									<span v-if="status"> New Node</span>
									<span v-else> Update Node</span>
								</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="node">Node Name *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.node_name}" type="text" v-model="node.node_name" placeholder="Node Name" ref="node_name">
                                            <span v-if="errors.node_name" class="invalid-feedback">{{ errors.node_name[0] }}</span>
                                        </div>
                                    </div>
									<div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="node">Display Name *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.display_name}" type="text" v-model="node.display_name" placeholder="Display Name">
                                            <span v-if="errors.display_name" class="invalid-feedback">{{ errors.display_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="description">Node Fields *</label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.description}" type="text" rows="5" v-model="node.description" placeholder="Node Fields"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button  class="btn btn-outline-danger" type="button" @click="discard">
                                        <i class="fas fa-ban"></i> Discard
                                    </button>
                                    <button class="btn btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
					<div class="col-sm-8">
						<div class="card">
                            <div class="card-header">
								<div class="card-title">
									<i class="fas fa-microchip"></i>
									IoT Nodes
								</div>
								<div class="card-tools">
									<input class="form-control form-control-sm search" type="text" placeholder="Search" v-model="meta.search" @keyup="index" ref="search">
								</div>
                            </div>
                            <div class="card-body">
                                <table class="table table-responsive-sm  table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Node Name</th>
											<th>Display Name</th>
                                            <th>Node Fields</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(node,key) in nodes" :key="key">
                                            <td class="text-center">{{ meta.from+key }}</td>
                                            <td>{{ node.node_name }}</td>
											<td>{{ node.display_name }}</td>
											<td>{{ node.description.substring(0,20) }}</td>
                                            <td class="text-center">
												<button v-can="'nodes.update'" class="btn btn-xs btn-outline-success" @click="edit(node)">
                                                    <i class="far fa-edit"></i>
                                                </button>
                                                <button v-can="'nodes.delete'" class="btn btn-xs btn-outline-danger" @click="destroy(node)">
                                                    <i class="far fa-trash-alt"></i>
                                                </button>
											</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer clearfix">
								<select class="form-control form-control-sm per_page float-left" type="text" placeholder="Search" v-model="meta.per_page" @change="onPerPageChange">
									<option>5</option>
									<option>10</option>
									<option>15</option>
									<option>20</option>
									<option>25</option>
								</select>
								<Pagination
									:maxPage="meta.maxPage"
									:totalPages="meta.lastPage"
									:currentPage="meta.currentPage"
									@pagechanged="onPageChange"
								/>
							</div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination.vue';
	export default {
		name: 'Home',
		components: { Pagination },
		data(){
            return{
                status:true,
                node:{
                    node_id:'',
                    node_name:'',
					display_name:'',
                    amount:'',
                    description:'',
                },
				meta: {
                    search:'',
					order_by:'asc',
					keyword:'node_name',
					per_page:5,
                    totalRows:0,
                    currentPage:1,
                    lastPage:1,
                    from: 1,
                    maxPage:1,
                },
				nodes:[],
                errors:[]
            }
        },
		mounted() {
            let vm = this;
            vm.discard();
        },
		methods:{
			discard(){
                let vm = this;
                vm.errors=[],
                vm.node.node_id='',
                vm.node.node_name='',
				vm.node.display_name='',
                vm.node.description='',
                vm.status=true,
                vm.$refs.node_name.focus(),
                vm.index()
            },

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			index() {
				let vm = this;
				let uri = 'nodes?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&keyword='+vm.meta.keyword+'&per_page='+vm.meta.per_page;
				vm.$store.dispatch('get',{'uri':uri})
				.then(function (response) {
					vm.nodes = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('post',{'uri':'nodes','data':vm.node})
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','IOT Node is successfully created');
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(node){
                let vm = this;
                vm.node = node,
                vm.status = false,
                vm.$refs.node_name.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'nodes/'+vm.node.node_id,'data':vm.node};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','IOT Node is successfully updated');
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			destroy(node) {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'nodes/'+node.node_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','IOT Node is successfully deleted');
						vm.discard();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

			onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            }
		}
	}
</script>
