<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fab fa-aws"></i>
							IOT Data
						</h3>
					</div>
				</div>
			</div>
		</section>
		<!-- Main content -->
		<div class="content">
			<div class="container">
				<div class="card">
					<div class="card-header">
						<div class="row">
							<div class="col-lg-2">
								<div class="form-group">
									<label class="form-label">Farm</label>
									<select class="form-control" v-model="filter.farm_id" ref="farm_id" @change="getSubFarms">
										<option value="">Select Farm</option>
										<option v-for="farm,key in farms" :key="key" :value="farm.farm_id">{{ farm.farm_name }}</option>
									</select>
								</div>
							</div>
							<div class="col-lg-2">
								<div class="form-group">
									<label class="form-label">Crop</label>
									<select class="form-control" v-model="filter.sub_farm_id">
										<option value="">Select SubFarm</option>
										<option v-for="sub_farm,key in sub_farms" :key="key" :value="sub_farm.sub_farm_id">{{ sub_farm.crop.crop_name }}</option>
									</select>
								</div>
							</div>
							<div class="col-lg-2">
								<div class="form-group">
									<label class="form-label">IoT Node</label>
									<select class="form-control" v-model="filter.node_name" @change="getIoTData">
										<option value="">Select IoT Nodes</option>
										<option v-for="farm_node,key in farm_nodes" :key="key" :value="farm_node.node.node_name">{{ farm_node.node.node_name }}</option>
									</select>
								</div>
							</div>
							<div class="col-lg-2">
								<div class="form-group">
									<label class="form-label">Parameter</label>
									<select class="form-control" v-model="filter.field_name" @change="getIoTAnalytics">
										<option value="">Select Parameter</option>
										<option v-for="node,key in nodes" :key="key" :value="node.field_name">{{ node.field_name }}</option>
									</select>
								</div>
							</div>
							<div class="col-lg-2">
								<div class="form-group">
									<label class="form-label">From Date</label>
									<input type="date" class="form-control" v-model="filter.from_date" @change="getIoTAnalytics">
								</div>
							</div>
							<div class="col-lg-2">  
								<div class="form-group">
									<label class="form-label">To Date</label>  
									<input type="date" class="form-control" v-model="filter.to_date" @change="getIoTAnalytics">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-6 col-md-4" v-for="node,key in nodes" :key="key">
						<div class="info-box">
							<span class="info-box-icon bg-info elevation-1"><i class="fas fa-cog"></i></span>

							<div class="info-box-content">
								<span class="info-box-text">{{ node.date }}</span>
								<span class="info-box-number">
								{{ node.field_name }} - {{ node.field_value }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12">
						<canvas ref="myChart" height="100"></canvas>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Chart from 'chart.js';
	export default {
		name: 'Home',
		data(){
			return{
				filter:{
					farm_id:'',
					sub_farm_id:'',
					user_id:this.$store.state.user.user_id,
					role:this.$store.state.user.role,
					node_name:'',
					field_name:'',
					from_date:new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().slice(0,10),
					to_date:new Date().toISOString().slice(0,10),
				},
				farms:[],
				sub_farms:[],
				farm_nodes:[],
				nodes:[],
				analytics:{},
				errors:[]
			}
		},

		beforeRouteEnter(to, from, next) {
			next(vm => {
                vm.filter.farm_id = to.query.farm_id;
				vm.filter.sub_farm_id = to.query.sub_farm_id;
				vm.getFarms();
			});
		},
		
		methods:{
			getFarms() {
				let vm = this;
                let uri = {'uri':'farms?user_id='+vm.filter.user_id+'&role='+vm.filter.role};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.farms = response.data.data;
					if(vm.farms.length!=0) {
						vm.getSubFarms();
					}
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getSubFarms() {
				let vm = this;
                let uri = {'uri':'sub_farms?farm_id='+vm.filter.farm_id+'&user_id='+vm.filter.user_id,'data':vm.filter};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.sub_farms = response.data.data;
					if(vm.sub_farms.length!=0){
						// vm.filter.sub_farm_id = vm.sub_farms[0].sub_farm_id
					}
					vm.getIotNodes()
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
			getIotNodes() {
				let vm = this;
                let uri = {'uri':'farm_nodes?farm_id='+vm.filter.farm_id+'&sub_farm_id='+vm.filter.sub_farm_id};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.farm_nodes = response.data.data;
					if(vm.farm_nodes.length!=0) {
						vm.filter.node_name = vm.farm_nodes[0].node.node_name;
						vm.getIoTData();
					}
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getIoTData() {
				let vm = this;
                let uri = {'uri':'get_iot_data?farm_id='+vm.filter.farm_id+'&sub_farm_id='+vm.filter.sub_farm_id+'&node_name='+vm.filter.node_name};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.nodes = response.data;
					if(vm.nodes.length!=0) {
						vm.filter.field_name = vm.nodes[0].field_name;
						vm.getIoTAnalytics();
					}
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getIoTAnalytics() {
				let vm = this;
                let uri = {'uri':'get_iot_analytics?farm_id='+vm.filter.farm_id+'&sub_farm_id='+vm.filter.sub_farm_id+'&node_name='+vm.filter.node_name+'&field_name='+vm.filter.field_name+'&from_date='+vm.filter.from_date+'&to_date='+vm.filter.to_date};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					vm.analytics = response.data;
					vm.createChart()
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			createChart() {
                let vm = this;
                if(vm.chart) {
                    vm.chart.destroy()
                }
                vm.chart = new Chart(this.$refs.myChart, {
                    type: vm.analytics.chart,
                    data: vm.analytics,
                    options: {
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                },
                            }]
                        }
                    }
                });
            },
		}
	}
</script>
<style scoped>
	
</style>