<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-tasks"></i>
							Actions
						</h3>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-4" v-can="'actions.create'">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
								<div class="card-title">
									<i class="fas fa-tasks"></i>
									<span v-if="status"> New Action</span>
									<span v-else> Update Action</span>
								</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="action_name">Action Name *</label>
                                            <input class="form-control form-control-sm" :class="{'is-invalid': errors.action_name}" type="text" v-model="action.action_name" placeholder="Action Name" ref="action_name">
                                            <span v-if="errors.action_name" class="invalid-feedback">{{ errors.action_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="action_type">Action Type *</label>
                                            <select class="form-control form-control-sm" :class="{'is-invalid': errors.action_type}" v-model="action.action_type" ref="action_type">
                                                <option value="Compound">Compound</option>
                                                <option value="Labour">Labour</option>
                                            </select>
                                            <span v-if="errors.action_type" class="invalid-feedback">{{ errors.action_type[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">   
                                        <div class="form-group">
                                            <label class="form-label" for="description">Description *</label>
                                            <textarea class="form-control form-control-sm" :class="{'is-invalid': errors.description}" type="text" v-model="action.description" placeholder="Description"></textarea>
                                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button  class="btn btn-outline-danger" type="button" @click="discard">
                                        <i class="fas fa-ban"></i> Discard
                                    </button>
                                    <button class="btn btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
					<div class="col-sm-8">
						<div class="card">
                            <div class="card-header">
								<div class="card-title">
									<i class="fas fa-tasks"></i>
									Actions
								</div>
								<div class="card-tools">
									<input class="form-control form-control-sm search" type="text" placeholder="Search" v-model="meta.search" @keyup="index" ref="search">
								</div>
                            </div>
                            <div class="card-body">
                                <table class="table table-responsive-sm  table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>Action Name</th>
                                            <th>Action Type</th>
                                            <th>Description</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(action,key) in actions" :key="key">
                                            <td class="text-center">{{ meta.from+key }}</td>
                                            <td>{{ action.action_name }}</td>
                                            <td>{{ action.action_type }}</td>
                                            <td>{{ action.description }}</td>
                                            <td class="text-center">
												<button v-can="'actions.update'" class="btn btn-xs btn-outline-success" @click="edit(action)">
                                                    <i class="far fa-edit"></i>
                                                </button>
                                                <button v-can="'actions.delete'" class="btn btn-xs btn-outline-danger" @click="destroy(action)">
                                                    <i class="far fa-trash-alt"></i>
                                                </button>
											</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer clearfix">
								<select class="form-control form-control-sm per_page float-left" type="text" placeholder="Search" v-model="meta.per_page" @change="onPerPageChange">
									<option>5</option>
									<option>10</option>
									<option>15</option>
									<option>20</option>
									<option>25</option>
								</select>
								<Pagination
									:maxPage="meta.maxPage"
									:totalPages="meta.lastPage"
									:currentPage="meta.currentPage"
									@pagechanged="onPageChange"
								/>
							</div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination.vue';
	export default {
		name: 'Actions',
		components: { Pagination },
		data(){
            return{
                status:true,
                action:{
                    action_id:'',
                    action_name:'',
                    action_type:'',
                    description:'',
                },
				meta: {
                    search:'',
					order_by:'asc',
					keyword:'action_name',
					per_page:5,
                    totalRows:0,
                    currentPage:1,
                    lastPage:1,
                    from: 1,
                    maxPage:1,
                },
				actions:[],
                errors:[]
            }
        },
		mounted() {
            let vm = this;
            vm.discard();
        },
		methods:{
			discard(){
                let vm = this;
                vm.errors=[],
                vm.action.action_id='',
                vm.action.action_name='',
                vm.action.action_type='Compound',
                vm.action.description='',
                vm.status=true,
                vm.$refs.action_name.focus(),
                vm.index()
            },

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			index() {
				let vm = this;
				let uri = 'actions?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&keyword='+vm.meta.keyword+'&per_page='+vm.meta.per_page;
				vm.$store.dispatch('get',{'uri':uri})
				.then(function (response) {
					vm.actions = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('post',{'uri':'actions','data':vm.action})
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Action is successfully created');
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(action){
                let vm = this;
                vm.action = action,
                vm.status = false,
                vm.$refs.action_name.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'actions/'+vm.action.action_id,'data':vm.action};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Action is successfully updated');
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			destroy(action) {
				let vm = this;
				if(confirm('Do you want to delete this entry ?')) {
					let loader = vm.$loading.show();
					let uri = {'uri':'actions/'+action.action_id};
					vm.$store.dispatch('delete',uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch('success','Action is successfully deleted');
						vm.discard();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },
             
            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            }
		}
	}
</script>
