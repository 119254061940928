<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-cogs"></i>
							Settings
						</h3>
					</div>
				</div>
			</div>
		</section>

		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-3">
						<div class="card">
							<div class="card-header">
								<i class="fa fa-cog" aria-hidden="true"></i>
								<b> Configuration</b>
							</div>
							<ul class="list-group">
								<router-link v-can="'crops.view'" to="/crops" class="list-group-item">
									<i class="fas fa-seedling" aria-hidden="true"></i>
									Crops
								</router-link>
								<router-link v-can="'stages.view'"  to="/stages" class="list-group-item">
									<i class="fas fa-walking" aria-hidden="true"></i>
									Stages
								</router-link>
								<router-link v-can="'actions.view'" to="/actions" class="list-group-item">
									<i class="fas fa-tasks" aria-hidden="true"></i>
									Actions
								</router-link>
								<router-link v-can="'nodes.view'" to="/nodes" class="list-group-item">
									<i class="fas fa-microchip" aria-hidden="true"></i>
									IoT Nodes
								</router-link>
							</ul>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="card">
							<div class="card-header">
								<i class="far fa-user" aria-hidden="true"></i>
								<b> Users, Roles & Permissions</b>
							</div>
							<ul class="list-group">
								<router-link v-can="'roles.view'" to="/roles" class="list-group-item">
									<i class="fas fa-user-tag" aria-hidden="true"></i>
									Roles
								</router-link>
								<router-link v-can="'users.view'" to="/users" class="list-group-item">
									<i class="far fa-user" aria-hidden="true"></i>
									Users
								</router-link>
								<router-link v-can="'permissions.view'" to="/permissions" class="list-group-item">
									<i class="fas fa-lock" aria-hidden="true"></i>
									Permissions
								</router-link>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Configuration'
	}
</script>
