import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const app = createApp(App); 
app.use(store);
app.use(router);
app.use(VueLoading);

app.directive('can', {
    mounted: function (el, binding) {
        let permissions = JSON.parse(localStorage.getItem('permissions'));
        if(permissions && permissions.length!=0) {
            let permission = permissions.filter(function (el) {
                return el.permission == binding.value;
            });
            if (!permission[0]) {
                el.parentNode.removeChild(el);
            }
        }
        else {
            el.parentNode.removeChild(el);
        }
    }
});

app.config.globalProperties.$filters = {
    formatDate(value) {
        if (value) {
            return moment(String(value)).format('DD-MM-YYYY')
        }
    },

    formatDateTime(value) {
        if (value) {
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss')
        }
    },
        
}

app.mount('#app');