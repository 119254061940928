<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-user"></i>
							Profile
						</h3>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-8">
                        <form class="card" @submit.prevent="updateProfile">
                            <div class="card-header">
                                <div class="card-title">Update Profile</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Name *</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.name}" placeholder="Name" v-model="user.name" ref="name">
											<span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Email Address *</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="user.email">
											<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Mobile No *</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile No" v-model="user.mobile_no">
											<span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Role *</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.role}" placeholder="Role" v-model="user.role" readonly>
											<span v-if="errors.role" class="invalid-feedback">{{ errors.role[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Aadhar No</label>
											<input type="number" class="form-control" :class="{'is-invalid': errors.aadhar_no}" placeholder="Aadhar No" v-model.number="user.aadhar_no">
											<span v-if="errors.aadhar_no" class="invalid-feedback">{{ errors.aadhar_no[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Qualification</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.qualification}" placeholder="Qualification" v-model="user.qualification">
											<span v-if="errors.qualification" class="invalid-feedback">{{ errors.qualification[0] }}</span>
										</div>
									</div>
									<div class="col-sm-12">
										<div class="form-group">
											<label class="form-label">Address *</label>
											<textarea class="form-control" :class="{'is-invalid': errors.address}" placeholder="Address" v-model="user.address"></textarea>
											<span v-if="errors.address" class="invalid-feedback">{{ errors.address[0] }}</span>
										</div>
									</div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-primary" type="submit">
										<span>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-4">
                        <form class="card" @submit.prevent="updatePassword">
                            <div class="card-header">
                                <div class="card-title">Change Password</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Current Password</label>
                                            <input type="password" class="form-control" :class="{'is-invalid': errors.current_password}" v-model="password.current_password" placeholder="Current Password" />
                                            <span v-if="errors.current_password" class="invalid-feedback">{{ errors.current_password[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">New Password</label>
                                            <input type="password" class="form-control" :class="{'is-invalid': errors.new_password}" v-model="password.new_password" placeholder="New Password" />
                                            <span v-if="errors.new_password" class="invalid-feedback">{{ errors.new_password[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Confirm Password</label>
                                            <input type="password" class="form-control" :class="{'is-invalid': errors.confirm_password}" v-model="password.confirm_password" placeholder="Confirm Password" />
                                            <span v-if="errors.confirm_password" class="invalid-feedback">{{ errors.confirm_password[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <button class="btn btn-primary" type="submit">
										<span>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'Profile',
		data(){
            return{
                status:true,
                user:{
					user_id:'',
					name:'',
					email:'',
					mobile_no:'',
					role_id:'',
					role:'',
					aadhar_no:'',
					qualification:'',
					address:'',
				},
                password:{
					user_id:'',
					current_password:'',
					new_password:'',
					confirm_password:'',
				},
				errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
                let loader = vm.$loading.show();
				let uri = {'uri':'users/'+vm.$store.getters.user.user_id};
                vm.$store.dispatch('get',uri)
                .then(function (response) {
                    loader.hide();
                    vm.user = response.data.data;
					vm.user.role = vm.user.role.role;
                    vm.$refs.name.focus()
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error',error.response.data.message);
                });
			});
        },
		methods:{

			updateProfile() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'profile','data':vm.user};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Profile is successfully updated');
					vm.$router.push('/');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

            updatePassword() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'password','data':vm.password};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Password is successfully updated');
					vm.$router.push('/');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            }
		}
	}
</script>
