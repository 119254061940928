<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-book"></i>
								Pest Records
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link :to="'/pests?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-secondary">
								<i class="fas fa-arrow-left"></i>
								Back
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-sm-4" v-if="status && pest.copy!='Yes'">
                        <form class="card">
                            <div class="card-header">
                                <div class="card-title">
									<i class="far fa-image"></i>
									Pest Images
								</div>
                            </div>
                            <div class="card-body">
								<div class="text-center" v-for="file,key in pest.files" :key="key">
									<img class="img-thumbnail" :src="file">
								</div>
								<div class="form-group">
									<input type="file" class="form-control" id="customFile" :class="{'is-invalid': errors.files}" @change="onImageChange($event)" accept="image/*">
									<label class="form-label" for="customFile">Choose file</label>
								</div>
								<span v-if="errors.files" class="invalid-feedback">{{ errors.files[0] }}</span>
							</div>
						</form>
					</div>
					<div class="col-sm-4" v-if="status && pest.copy=='Yes'">
						<form class="card">
                            <div class="card-header">
                                <div class="card-title">
									<i class="far fa-image"></i>
									Pest Images
								</div>
                            </div>
                            <div class="card-body">
								<div class="text-center" v-for="file,key in pest.files" :key="key">
									<img :src="$store.getters.assetUrl+'files/'+file.file" class="img-thumbnail">
								</div>
							</div>
						</form>
					</div>
					<div class="col-sm-8">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
								<div class="card-title">
									<i class="fas fa-book"></i>
									<span v-if="status"> New Pest Record</span>
									<span v-else> Update Pest Record</span>
								</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-6">
										<div class="form-group">
											<label class="form-label">Farm *</label>
											<select disabled class="form-control form-control-sm" :class="{'is-invalid': errors.farm_id}" v-model="pest.farm_id" ref="farm_id" @change="getSubFarms">
												<option value="">Select Farm</option>
												<option v-for="farm,key in farms" :key="key" :value="farm.farm_id">{{ farm.farm_name }}</option>
											</select>
											<span v-if="errors.farm_id" class="invalid-feedback">{{ errors.farm_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<label class="form-label">Sub Farm *</label>
											<select disabled class="form-control form-control-sm" :class="{'is-invalid': errors.sub_farm_id}" v-model="pest.sub_farm_id">
												<option value="">Select SubFarm</option>
												<option v-for="sub_farm,key in sub_farms" :key="key" :value="sub_farm.sub_farm_id">{{ sub_farm.sub_farm_name }}</option>
											</select>
											<span v-if="errors.sub_farm_id" class="invalid-feedback">{{ errors.sub_farm_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Date *</label>
											<input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.date}" placeholder="Date" v-model="pest.date">
											<span v-if="errors.date" class="invalid-feedback">{{ errors.date[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Time *</label>
											<input type="time" class="form-control form-control-sm" :class="{'is-invalid': errors.time}" placeholder="Time" v-model="pest.time">
											<span v-if="errors.time" class="invalid-feedback">{{ errors.time[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Invigilator *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.user_id}" placeholder="Invigilator" v-model="pest.invigilator" readonly>
											<span v-if="errors.user_id" class="invalid-feedback">{{ errors.user_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Bed / Tower No</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.bed_tower_no}" placeholder="Bed / Tower No" v-model="pest.bed_tower_no">
											<span v-if="errors.bed_tower_no" class="invalid-feedback">{{ errors.bed_tower_no[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Pest Name</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.pest_name}" placeholder="Pest Name" v-model="pest.pest_name">
											<span v-if="errors.pest_name" class="invalid-feedback">{{ errors.pest_name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Pest Stage</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.pest_stage}" v-model="pest.pest_stage">
												<option value="">Select Pest Stage</option>
												<option>Egg</option>
												<option>Larva</option>
												<option>Pupa</option>
												<option>Adult</option>
											</select>
											<span v-if="errors.pest_stage" class="invalid-feedback">{{ errors.pest_stage[0] }}</span>
										</div>
									</div>
									<div class="col-sm-12">
										<div class="form-group">
											<label class="form-label">Note</label>
											<textarea class="form-control form-control-sm" :class="{'is-invalid': errors.note}" placeholder="Note" v-model="pest.note"></textarea>
											<span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
										</div>
									</div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link :to="'/pests?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-outline-danger" type="button">
                                        <i class="fas fa-ban"></i> Discard
                                    </router-link>
                                    <button class="btn btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'SubFarms.Create',
		data(){
            return{
                status:true,
                pest:{
                    pest_id:'',
					farm_id:'',
					sub_farm_id:'',
					date:new Date().toISOString().slice(0,10),
					time:new Date().toISOString().slice(11,16),
					user_id:'',
					invigilator:'',
					bed_tower_no:'',
					pest_name:'',
					pest_stage:'',
					files:[],
					copy:"",
					note:'',
				},
				farm_id:'',
				sub_farm_id:'',
				user_id:this.$store.state.user.user_id,
				role:this.$store.state.user.role,
				farms:[],
				sub_farms:[],
				errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.farm_id = to.query.farm_id;
				vm.sub_farm_id = to.query.sub_farm_id;
				if(to.query.copy) { 
					vm.pest.files = JSON.parse(to.query.files)
				}
				vm.pest.copy = to.query.copy;
				vm.pest.farm_id = vm.farm_id;
				vm.pest.sub_farm_id = vm.sub_farm_id;
				if(to.name=='Pests.Create') {
					vm.getFarms();
					vm.pest.user_id = vm.$store.getters.user.user_id;
					vm.pest.invigilator = vm.$store.getters.user.name;
					vm.$refs.farm_id.focus();
				}
				else {
					let loader = vm.$loading.show();
					let uri = {'uri':'pests/'+to.params.pest_id};
					vm.$store.dispatch('get',uri)
					.then(function (response) {
						loader.hide();
						vm.getFarms();
						vm.pest = response.data.data;
						vm.pest.invigilator = vm.pest.user.name;
						vm.status = false;
						vm.$refs.name.focus()
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			});
        },
		methods:{

			getFarms() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'farms?user_id='+vm.user_id+'&role='+vm.role,'data':vm.pest};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.farms = response.data.data;
					vm.getSubFarms();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getSubFarms() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'sub_farms?farm_id='+vm.farm_id+'&user_id='+vm.pest.user_id,'data':vm.pest};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.sub_farms = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			onImageChange(e) {
                e.preventDefault();
                let vm = this;
                let image = e.target.files[0]; 
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (e) => {
                    vm.pest.files.push(e.target.result);
                };
				console.log(vm.pest.files);
            },

			create() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'pests','data':vm.pest};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch('success','Pest Record is successfully created');
					vm.$router.push('/pests/'+response.data.data['pest_id']+'?farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(pest){
                let vm = this;
                vm.pest = pest,
                vm.status = false,
                vm.$refs.pest.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'pests/'+vm.pest.pest_id,'data':vm.pest};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Pest record is successfully updated');
					vm.$router.push('/pests/?farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            }
		}
	}
</script>
