<template>
	<div class="auth">
		<div class="col-xl-8 col-11">
			<div class="card auth-bg">
				<div class="row">
					<div class="col-md-12 col-12">
						<div class="card h-100 d-flex justify-content-center">
							<div class="card-body">
								<div class="heading">
									<div class="heading-text">
										swasahaya.com
									</div>
								</div>
								<form class="row" @submit.prevent="register">
									<div class="col-sm-4">
										<div class="form-group">
											<label for="first_name" class="form-label">First Name *</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.first_name}" placeholder="First Name" v-model="user.first_name" ref="first_name">
											<span v-if="errors.first_name" class="invalid-feedback">{{ errors.first_name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label for="middle_name" class="form-label">Middle Name</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.middle_name}" placeholder="Middle Name" v-model="user.middle_name" ref="middle_name">
											<span v-if="errors.middle_name" class="invalid-feedback">{{ errors.middle_name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label for="last_name" class="form-label">Last Name *</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.last_name}" placeholder="Last Name" v-model="user.last_name" ref="last_name">
											<span v-if="errors.last_name" class="invalid-feedback">{{ errors.last_name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Email Address</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="user.email">
											<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Mobile No</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile No" v-model="user.mobile_no">
											<span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Password</label>
											<input type="password" class="form-control" :class="{'is-invalid': errors.password}" placeholder="Password" v-model="user.password">
											<span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">State</label>
											<select class="form-control" :class="{'is-invalid': errors.state_id}"  v-model="user.state_id" @change="getDistricts">
												<option value="">Select State</option>
												<option v-for="state,key in states" :key="key" :value="state.state_id">{{ state.state }}</option>
											</select>
											<span v-if="errors.state_id" class="invalid-feedback">{{ errors.state_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">District</label>
											<select class="form-control" :class="{'is-invalid': errors.district_id}"  v-model="user.district_id" @change="getTaluks">
												<option value="">Select District</option>
												<option v-for="district,key in districts" :key="key" :value="district.district_id">{{ district.district }}</option>
											</select>
											<span v-if="errors.district_id" class="invalid-feedback">{{ errors.district_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Taluk</label>
											<select class="form-control" :class="{'is-invalid': errors.taluk_id}"  v-model="user.taluk_id" @change="getHobalis">
												<option value="">Select Taluk</option>
												<option v-for="taluk,key in taluks" :key="key" :value="taluk.taluk_id">{{ taluk.taluk }}</option>
											</select>
											<span v-if="errors.taluk_id" class="invalid-feedback">{{ errors.taluk_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Hobali</label>
											<select class="form-control" :class="{'is-invalid': errors.hobali_id}"  v-model="user.hobali_id">
												<option value="">Select Hobali</option>
												<option v-for="hobali,key in hobalis" :key="key" :value="hobali.hobali_id">{{ hobali.hobali }}</option>
											</select>
											<span v-if="errors.hobali_id" class="invalid-feedback">{{ errors.hobali_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label class="form-label">Address</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.address}" placeholder="Address" v-model="user.address">
											<span v-if="errors.address" class="invalid-feedback">{{ errors.address[0] }}</span>
										</div>
									</div>
									<div class="col-sm-12">
										<button type="submit" class="btn btn-primary w-100 position-relative">
											Register
											<i class="fa fa-arrow-right"></i>
										</button>
									</div>
									<div class="col-sm-12">
										<div class="text-center mt-2">
											<small>Don't have an account? </small>
											<router-link to="/login">
												<small>Login</small>
											</router-link>
										</div>
									</div>
								</form>
							</div>
						</div> 
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Login',
		data(){
			return{
				user:{
					name:'',
					email:'',
					mobile_no:'',
					password:'',
					role:'VLE',
					state_id:'',
					district_id:'',
					taluk_id:'',
					hobali_id:'',
					address:'',
				},
				states:[],
				districts:[],
				taluks:[],
				hobalis:[],
				errors:[]
			}
		},
		mounted(){
			let vm = this;
			vm.$refs.name.focus();
			vm.getStates();
		},
		methods:{
			getStates() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('auth',{'uri':'get_states','data':vm.user})
				.then(function (response) {
					loader.hide();
					vm.states = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
			getDistricts() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('auth',{'uri':'get_districts','data':vm.user})
				.then(function (response) {
					loader.hide();
					vm.districts = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
			getTaluks() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('auth',{'uri':'get_taluks','data':vm.user})
				.then(function (response) {
					loader.hide();
					vm.taluks = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
			getHobalis() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('auth',{'uri':'get_hobalis','data':vm.user})
				.then(function (response) {
					loader.hide();
					vm.hobalis = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
            register() {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store.dispatch('auth',{'uri':'register','data':vm.user})
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch('success','Thank you for registering with us');
					vm.$store.dispatch('setUser',response.data);
					vm.$router.push('/');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
            }
		}
	}
</script>