<template>
    <div v-if="status">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    <i class="far fa-user"></i>
                                    Investors
                                </h5>
                                <button type="button" class="close" @click="closeModal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <table class="table table-responsive-sm  table-hover table-sm">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th>Investor Name</th>
											<th>Investment</th>
											<th>Locking</th>
											<th>Return</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(farm_investor,key) in farm_investors" :key="key">
											<td class="text-center">{{ key+1 }}</td>
											<td>{{ farm_investor.user.name }}</td>
											<td>{{ farm_investor.investment }}</td>
											<td>{{ farm_investor.locking }}</td>
											<td>{{ farm_investor.return_expected }}</td>
											<td>{{ farm_investor.investment_status }}</td>
										</tr>
									</tbody>
								</table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name:'Modal',
        props: {
            farm_investors: {
                type: Array,
                required: true
            },
            status: {
                type: Boolean,
                required: true
            },
        },
        data(){
            return{
                errors:[]
            }
        },

        methods: {
            closeModal() {
                this.$emit('onStatusChange', false);    
            }
        }
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
</style>