<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-seelding"></i>
							Farms
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link to="/" class="btn btn-secondary">
								<i class="fas fa-arrow-left"></i>
								Back
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
								<i class="fas fa-tractor"></i>
                                <strong v-if="status"> New Farm</strong>
                                <strong v-else> Update Farm</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Farm Name *</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.farm_name}" placeholder="Farm Name" v-model="farm.farm_name" ref="farm_name">
											<span v-if="errors.farm_name" class="invalid-feedback">{{ errors.farm_name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Farm Type *</label>
											<select class="form-control" :class="{'is-invalid': errors.farm_type}" v-model="farm.farm_type">
												<option value="">Select Farm Type</option>
												<option>Company</option>
												<option>Individual</option>
											</select>
											<span v-if="errors.farm_type" class="invalid-feedback">{{ errors.farm_type[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Area (Acres)</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.area}" placeholder="Area (Acres)" v-model="farm.area">
											<span v-if="errors.area" class="invalid-feedback">{{ errors.area[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Revenue</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.revenue}" placeholder="Revenue" v-model="farm.revenue">
											<span v-if="errors.revenue" class="invalid-feedback">{{ errors.revenue[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Status</label>
											<select class="form-control" :class="{'is-invalid': errors.status}" v-model="farm.status">
												<option value="">Select Status</option>
												<option value="Open">Open</option>
												<option value="Closed">Closed</option>
											</select>
											<span v-if="errors.status" class="invalid-feedback">{{ errors.status[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Farmer Share %</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.former_share}" placeholder="Farmer Share" v-model="farm.former_share">
											<span v-if="errors.former_share" class="invalid-feedback">{{ errors.former_share[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Investor Share %</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.investor_share}" placeholder="Investor Share" v-model="farm.investor_share">
											<span v-if="errors.investor_share" class="invalid-feedback">{{ errors.investor_share[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Company Share %</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.company_share}" placeholder="Company Share" v-model="farm.company_share">
											<span v-if="errors.company_share" class="invalid-feedback">{{ errors.company_share[0] }}</span>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<label class="form-label">Coordinate (Lat)</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.latitude}" placeholder="Coordinate (Lat)" v-model="farm.latitude">
											<span v-if="errors.latitude" class="invalid-feedback">{{ errors.latitude[0] }}</span>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<label class="form-label">Coordinate (Long)</label>
											<input type="text" class="form-control" :class="{'is-invalid': errors.longitude}" placeholder="Coordinate (Long)" v-model="farm.longitude">
											<span v-if="errors.longitude" class="invalid-feedback">{{ errors.longitude[0] }}</span>
										</div>
									</div>
									<div class="col-sm-12">
										<div class="form-group">
											<label class="form-label">Location of Farm *</label>
											<textarea class="form-control" :class="{'is-invalid': errors.location}" placeholder="Location of Farm" v-model="farm.location"></textarea>
											<span v-if="errors.location" class="invalid-feedback">{{ errors.location[0] }}</span>
										</div>
									</div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/" class="btn btn-danger" type="button">
                                        <i class="fas fa-ban"></i> Discard
                                    </router-link>
                                    <button class="btn btn-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'farm.Create',
		data(){
            return{
                status:true,
                farm:{
                    farm_name:'',
					farm_type:'',
					area:'',
					latitude:'',
					longitude:'',
					location:'',
					user_id:this.$store.state.user.user_id,
					revenue:'',
					status:'',
					former_share:'',
					investor_share:'',
					company_share:''
				},
				errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				if(to.name=='Farms.Create') {
					vm.$refs.farm_name.focus();
				}
				else {
					let uri = {'uri':'farms/'+to.params.farm_id};
					vm.$store.dispatch('get',uri)
					.then(function (response) {
						vm.farm = response.data.data;
						vm.status = false;
						vm.$refs.name.focus()
					})
					.catch(function (error) {
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			});
        },
		methods:{

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'farms','data':vm.farm};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Farm is successfully created');
					vm.$router.push('/');
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(farm){
                let vm = this;
                vm.farm = farm,
                vm.status = false,
                vm.$refs.farm.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'farms/'+vm.farm.farm_id,'data':vm.farm};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Farm is successfully updated');
					vm.$router.push('/farms/'+vm.farm.farm_id);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            }
		}
	}
</script>
