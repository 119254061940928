<template>
    <div v-if="status">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-sm" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    <i class="far fa-image"></i>
                                    Upload Image
                                </h5>
                                <button type="button" class="close" @click="closeModal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div v-if="file">
                                    <img class="img-thumbnail" :src="file">
                                </div>
                                <div class="custom-file">
									<input type="file" class="custom-file-input" id="customFile" :class="{'is-invalid': errors.files}" @change="onImageChange($event)" accept="image/*">
									<label class="custom-file-label" for="customFile">Choose file</label>
								</div>
								<span v-if="errors.file" class="invalid-feedback">{{ errors.file[0] }}</span>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-sm btn-outline-danger" @click="closeModal">
                                    <i class="fas fa-ban"></i> Discard
                                </button>
                                <button type="button" class="btn btn-sm btn-outline-success" @click="uploadFile">
                                   <i class="fas fa-upload"></i> Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name:'Modal',
        props: {
            module_id: {
                type: Number,
                required: true
            },
            module: {
                type: String,
                required: true
            },
            status: {
                type: Boolean,
                required: true
            },
        },
        data(){
            return{
                file:'',
                errors:[]
            }
        },
        methods: {
            onImageChange(e) {
                e.preventDefault();
                let vm = this;
                let image = e.target.files[0]; 
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (e) => {
                    vm.file = e.target.result;
                };
            },
            closeModal() {
                this.file = "";
                this.$emit('onStatusChange', false);    
            },
            uploadFile() { 
                let vm = this;
				let loader = vm.$loading.show();
                let data = {
                    'module_id':vm.module_id,
                    'module':vm.module,
                    'file':vm.file,
                }
				let uri = {'uri':'files','data':data};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch('success','Image is successfully uploaded');
                    vm.file = "";
                    vm.$emit('onUploadFile',response.data.data)
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
            }
        }
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
</style>