<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container">
				<div class="row">
					<div class="col-sm-6">
						<h3>
							<i class="fas fa-credit-card"></i>
							Expenses
						</h3>
					</div>
					<div class="col-sm-6">
						<div class="float-sm-right">
							<router-link :to="'/seeds?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-secondary">
								<i class="fas fa-arrow-left"></i>
								Back
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="content">
			<div class="container">
				<ExpenseNav :farm_id="farm_id" :sub_farm_id="sub_farm_id" />
				<div class="row">
					<div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm">
                            <div class="card-header">
                                <strong v-if="status">New Agriculture Input Expense</strong>
                                <strong v-else>Update Agriculture Input Expense</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
									<div class="col-sm-6">
										<div class="form-group">
											<label class="form-label">Farm *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.farm_id}" v-model="seed.farm_id" ref="farm_id" @change="getSubFarms">
												<option value="">Select Farm</option>
												<option v-for="farm,key in farms" :key="key" :value="farm.farm_id">{{ farm.farm_name }}</option>
											</select>
											<span v-if="errors.farm_id" class="invalid-feedback">{{ errors.farm_id[0] }}</span>
										</div>
									</div>
									<!-- <div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Crop *</label>
											<select class="form-control form-control-sm" :class="{'is-invalid': errors.sub_farm_id}" v-model="seed.sub_farm_id">
												<option value="">Select SubFarm</option>
												<option v-for="sub_farm,key in sub_farms" :key="key" :value="sub_farm.sub_farm_id">{{ sub_farm.crop.crop_name }}</option>
											</select>
											<span v-if="errors.sub_farm_id" class="invalid-feedback">{{ errors.sub_farm_id[0] }}</span>
										</div>
									</div> -->
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Date *</label>
											<input type="date" class="form-control form-control-sm" :class="{'is-invalid': errors.date}" placeholder="Date" v-model="seed.date">
											<span v-if="errors.date" class="invalid-feedback">{{ errors.date[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Time *</label>
											<input type="time" class="form-control form-control-sm" :class="{'is-invalid': errors.time}" placeholder="Time" v-model="seed.time">
											<span v-if="errors.time" class="invalid-feedback">{{ errors.time[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Company Name *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.supplier_name}" placeholder="Company Name" v-model="seed.supplier_name">
											<span v-if="errors.supplier_name" class="invalid-feedback">{{ errors.supplier_name[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Chemical Type *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.seed_type}" placeholder="Chemical Type" v-model="seed.seed_type">
											<span v-if="errors.seed_type" class="invalid-feedback">{{ errors.seed_type[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Quantity *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.quantity}" placeholder="Quantity" v-model="seed.quantity">
											<span v-if="errors.quantity" class="invalid-feedback">{{ errors.quantity[0] }}</span>
										</div>
									</div>
									<div class="col-sm-3">
										<div class="form-group">
											<label class="form-label">Total Cost *</label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.total_cost}" placeholder="Total Cost" v-model="seed.total_cost">
											<span v-if="errors.total_cost" class="invalid-feedback">{{ errors.total_cost[0] }}</span>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<label class="form-label">Compounds</label>
											<textarea class="form-control form-control-sm" :class="{'is-invalid': errors.compounds}" placeholder="Compounds" v-model="seed.compounds"></textarea>
											<span v-if="errors.compounds" class="invalid-feedback">{{ errors.compounds[0] }}</span>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<label class="form-label">Note</label>
											<textarea class="form-control form-control-sm" :class="{'is-invalid': errors.note}" placeholder="Note" v-model="seed.note"></textarea>
											<span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
										</div>
									</div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link :to="'/seeds?farm_id='+farm_id+'&sub_farm_id='+sub_farm_id" class="btn btn-outline-danger" type="button">
                                        <i class="fas fa-ban"></i> Discard
                                    </router-link>
                                    <button class="btn btn-outline-success" type="submit">
                                        <span v-if="status">
											<i class="fas fa-save"></i> Submit
										</span>
										<span v-else>
											<i class="fas fa-save"></i> Update
										</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import ExpenseNav from '@/components/ExpenseNav.vue';
	export default {
		name: 'Seeds.Create',
		components:{
			ExpenseNav
		},

		data(){
            return{
                status:true,
                seed:{
                    seed_id:'',
					farm_id:'',
					sub_farm_id:'',
					date:new Date().toISOString().slice(0,10),
					time:new Date().toISOString().slice(11,16),
					user_id:'',
					supplier_name:'',
					seed_type:'',
					quantity:'',
					total_cost:'',
					note:'',
					compounds:'',
				},
				farm_id:'',
				sub_farm_id:'',
				user_id:this.$store.state.user.user_id,
				role:this.$store.state.user.role,
				farms:[],
				sub_farms:[],
				errors:[]
            }
        },
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.farm_id = to.query.farm_id;
				vm.sub_farm_id = to.query.sub_farm_id;
				vm.seed.farm_id = vm.farm_id;
				vm.seed.sub_farm_id = vm.sub_farm_id;
				if(to.name=='Seeds.Create') {
					vm.getFarms();
					vm.seed.user_id = vm.$store.getters.user.user_id;
					vm.$refs.farm_id.focus();
				}
				else {
					let loader = vm.$loading.show();
					let uri = {'uri':'seeds/'+to.params.seed_id};
					vm.$store.dispatch('get',uri)
					.then(function (response) {
						loader.hide();
						vm.getFarms();
						vm.seed = response.data.data;
						vm.status = false;
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch('error',error.response.data.message);
					});
				}
			});
        },
		methods:{

			getFarms() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'farms?user_id='+vm.user_id+'&role='+vm.role};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.farms = response.data.data;
					vm.getSubFarms();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getSubFarms() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'sub_farms?farm_id='+vm.seed.farm_id,'data':vm.seed};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.sub_farms = response.data.data;
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			calTotalCost() {
				let vm = this;
				vm.seed.total_cost = (vm.seed.quantity * vm.seed.seed_type).toFixed(2);
			},

			submitForm() {
				let vm = this;
				if(vm.status) {
					vm.create();
				}
				else {
					vm.update();
				}
			},

			create() {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'seeds','data':vm.seed};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Expense is successfully created');
					vm.$router.push('/seeds/?farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			edit(seed){
                let vm = this;
                vm.seed = seed,
                vm.status = false,
                vm.$refs.seed.focus()
            },

			update() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'seeds/'+vm.seed.seed_id,'data':vm.seed};
				vm.$store.dispatch('patch',uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch('success','Expense is successfully updated');
					vm.$router.push('/seeds/?farm_id='+vm.farm_id+'&sub_farm_id='+vm.sub_farm_id);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},
		}
	}
</script>
