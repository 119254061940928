<template>
	<div class="wrapper" v-if="$store.getters.user">
		<Header></Header>
		<router-view/>
	</div>
	<div class="wrapper" v-else>
		<router-view/>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	export default {
		name: 'App',
		components: { Header },
		created() {
			// let path = window.location.origin.split( ':8' );
			// this.$store.commit('setUrl',path[0]);
			this.$store.dispatch('autoLogin');
		}
	}
</script>
